/**
 * Parse the JWT and return the header and payload.
 *
 * @see https://jwt.io/
 *
 * @param {string} token
 * @return {{payload: ({}), header: ({})}}
 */
export default function parseJwt(token) {
  const jwtParts = token.split('.');

  return {
    header: JSON.parse(atob(jwtParts[0])) || {},
    payload: JSON.parse(atob(jwtParts[1])) || {},
  };
}
