<template>
  <v-row justify="center" no-gutters>
    <v-col sm="12" md="8" lg="6" xl="4">
      <v-row>
        <v-col>
          <v-card outlined>

            <v-toolbar flat dense>
              <v-btn
                :to="{ name: 'Account' }"
                data-test="navBackBtn"
                exact-path
                icon
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-toolbar>

            <v-img
              :src="require('@/assets/undraw/undraw_a_whole_year_vnfm.svg')"
              alt=""
              class="shrink my-5"
              contain
              height="152"
              transition="scale-transition"
            />

            <v-card-title>
              <span>{{ _('session') }}</span>
              <v-divider inset></v-divider>
            </v-card-title>

            <v-card-text>
              <v-simple-table dense>
                <tbody>
                <template v-for="(item, k) in  sessionData">
                  <tr :key="k">
                    <td class="table-key-column text-right">{{ item.label }}</td>
                    <td>{{ item.value }}</td>
                  </tr>
                </template>
                </tbody>
              </v-simple-table>
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import translateMixin from '@/mixins/translate';

export default {
  name: 'AccountSessionView',

  mixins: [translateMixin],

  data() {
    return {
      // @see translateMixin.translateKeyPrefix
      translateKeyPrefix: 'views.account.session',
    };
  },

  computed: {
    sessionData() {
      const payload = this.$store.getters['session/payload'];
      const intl = Intl.DateTimeFormat('nl', { dateStyle: 'long', timeStyle: 'short' });

      return {
        created: {
          label: this._('session_created_on'),
          value: intl.format((payload.iat || 0) * 1000),
        },
        expired: {
          label: this._('session_expires_on'),
          value: intl.format((payload.exp || 0) * 1000),
        },
        username: {
          label: this._('identity'),
          value: payload.email || '',
        },
        firstName: {
          label: this._('first_name'),
          value: payload.firstName || '',
        },
        lastName: {
          label: this._('last_name'),
          value: payload.lastName || '',
        },
        function: {
          label: this._('function'),
          value: payload.function || '',
        },
        company: {
          label: this._('company'),
          value: payload.companyName || '',
        },
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.table-key-column
  width: 150px
  font-weight: bold
</style>
