/**
 * @see https://spec.openapis.org/oas/latest.html#schema-object
 */
export default class Schema {
  /**
   * @type {?string}
   */
  type;

  /**
   * @type {?string}
   */
  description;

  /**
   * @type {?{string: {}}}
   */
  properties;

  /**
   * @type {array<string>}
   */
  enum;

  constructor(schema) {
    this.type = schema?.type || null;
    this.description = schema?.description || null;
    this.properties = schema?.properties || null;
    this.enum = schema?.enum || [];
  }
}
