import ClientError from '@/services/http/errors/ClientError';

/**
 * A HTTP 400 Bad Request error.
 */
export default class BadRequestError extends ClientError {
  /**
   * The response body. This body contains details about the error.
   */
  responseBody;

  /**
   * @param {Response|string|undefined} response
   * @param {?Object} body The json decoded response body.
   */
  constructor(response, body) {
    super(response);

    if ((response instanceof Response) === false) {
      this.statusCode = 400;
      this.message = 'Bad Request';
    }

    this.responseBody = body;
  }

  /**
   * Returns the error detail.
   *
   * @return {string|null}
   */
  getDetails() {
    const { responseBody } = this;

    return responseBody.details || null;
  }
}
