import Components from '@/services/openapi/model/Components';
import Info from '@/services/openapi/model/Info';
import Server from '@/services/openapi/model/Server';

/**
 * @see https://spec.openapis.org/oas/latest.html#openapi-object
 */
export default class OpenApi {
  /**
   * @type {string}
   */
  openapi;

  /**
   * @type {Info}
   */
  info;

  /**
   * @type {array<Server>}
   */
  servers = [];

  /**
   * @type {Components}
   */
  components = null;

  constructor(openApi) {
    this.openapi = openApi?.openapi || '';
    this.info = new Info(openApi?.info || {});
    this.components = new Components(openApi?.components || {});

    if (Array.isArray(openApi?.servers) === true) {
      openApi.servers.forEach((serverSpec) => this.servers.push(new Server(serverSpec)));
    }
  }

  /**
   * @return {{string: any}}
   */
  get dto() {
    function replacer(key, value) {
      if (value instanceof Map) {
        return Array.from(value.entries());
      }

      return value;
    }

    return JSON.parse(JSON.stringify(this, replacer));
  }
}
