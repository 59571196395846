/**
 * @see https://spec.openapis.org/oas/latest.html#server-object
 */
import ServerVariable from '@/services/openapi/model/ServerVariable';

export default class Server {
  /**
   * @type {string}
   */
  url;

  /**
   * @type {string}
   */
  description;

  /**
   * @type {Map<string, ServerVariable>}
   */
  variables;

  constructor(server) {
    this.url = server?.url || '';
    this.description = server?.description || '';

    const variables = Object
      .entries(server?.variables || {})
      .map(([name, spec]) => [name, new ServerVariable(spec)]);

    this.variables = new Map(variables);
  }
}
