<template>
  <v-app>
    <AppNavigation></AppNavigation>
    <AppBar></AppBar>

    <v-main>
      <v-container data-test="mainLayout">
        <v-slide-x-reverse-transition mode="out-in">
          <router-view/>
        </v-slide-x-reverse-transition>
      </v-container>
    </v-main>

    <AppMessage></AppMessage>
  </v-app>
</template>

<script>
import AppBar from '@/components/layout/AppBar.vue';
import AppMessage from '@/components/layout/AppMessage.vue';
import AppNavigation from '@/components/layout/AppNavigation.vue';

/**
 * The main layout.
 * This layout contains navigation, top toolbar and a footer.
 */
export default {
  name: 'MainLayout',

  components: {
    AppBar,
    AppMessage,
    AppNavigation,
  },
};
</script>
