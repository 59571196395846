import Schema from '@/services/openapi/model/Schema';

export default class Components {
  /**
   * @type {Map<string, Schema>}
   */
  schemas;

  constructor(components) {
    const schemas = Object
      .entries(components?.schemas || {})
      .map(([name, spec]) => [name, new Schema(spec)]);

    this.schemas = new Map(schemas);
  }
}
