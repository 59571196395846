import AbstractModel from '@/model/AbstractModel';

export default class Address extends AbstractModel {
  static entity = 'address';

  static primaryKey = 'id';

  static updateMethod = AbstractModel.PUT;

  static fields() {
    return {
      id: this.number(null).nullable(),
      company_id: this.number(null),

      type: this.string(null).nullable(),

      address: this.string(null).nullable(), // streetname
      nr: this.string(null).nullable(),
      postalCode: this.string(null).nullable(),
      location: this.string(null).nullable(),
      country: this.string(null).nullable(),

      phoneNr: this.string(null).nullable(),
      email: this.string(null).nullable(),
    };
  }

  get dto() {
    const dto = this.$toJson();
    delete dto.company_id;

    return dto;
  }

  set dto(data) {
    this.$fill({ ...data, id: data.id });
  }
}
