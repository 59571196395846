import ClientError from '@/services/http/errors/ClientError';

/**
 * An HTTP 422 Unprocessable Entity error.
 *
 * These errors come with problem details which can be used for detailed error messages.
 */
export default class UnprocessableEntityError extends ClientError {
  /**
   * The response body. This body contains details about the error.
   */
  responseBody;

  /**
   * @param {Response|string|undefined} response
   * @param {?Object} body The json decoded response body.
   */
  constructor(response, body) {
    super(response);

    if ((response instanceof Response) === false) {
      this.statusCode = 422;
      this.message = 'Unprocessable Entity';
    }

    this.responseBody = body;
    this.violations = body?.violations || [];
  }

  /**
   * Returns the violations which can be used by forms to display messages on field level.
   *
   * @return {{string: string[]}}
   */
  getViolations() {
    const { responseBody } = this;
    const violationDetails = responseBody?.violations || [];
    const violations = {};

    violationDetails.forEach((violation) => {
      const { propertyPath, message } = violation;
      if (Array.isArray(violations[propertyPath]) === false) {
        violations[propertyPath] = [];
      }

      violations[propertyPath].push(message);
    });

    return violations;
  }

  /**
   * Returns the error detail.
   *
   * @return {string|null}
   */
  getDetails() {
    const { responseBody } = this;

    return responseBody.details || null;
  }
}
