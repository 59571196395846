/**
 * A HTTP 4xx client error.
 */
export default class ClientError extends Error {
  /**
   * The Response object causing this error, or a error message.
   *
   * @type {Response|string|undefined}
   */
  response;

  /**
   * The response status code. Or 0 when there in no Response.
   *
   * @type {number}
   */
  statusCode;

  /**
   * @param {Response|string|undefined} response
   */
  constructor(response) {
    let statusCode = 0;
    let statusText = 'Client error';

    if (response instanceof Response) {
      statusCode = response.status;
      statusText = response.statusText;
    }

    if (typeof response === 'string') {
      statusText = response;
    }

    super(statusText);

    this.response = response;
    this.statusCode = statusCode;
  }
}
