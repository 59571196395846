<template>
  <v-dialog
    v-model="isDialogOpen"
    :max-width="maxWidth"
    persistent
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        :disabled="disabled"
        class="editable-list-item"
        data-test="editableListItemActivator"
        v-bind="attrs"
        v-on="on"
      >

        <v-list-item-icon v-if="itemIcon">
          <v-icon v-if="hasError" color="error">mdi-alert-circle-outline</v-icon>
          <v-icon v-else>{{ itemIcon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-if="dataValue">{{ dataText }}</v-list-item-title>
          <v-list-item-title v-else class="grey--text">{{ placeholder }}</v-list-item-title>
          <v-list-item-subtitle v-if="subTitle">{{ subTitle }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action v-if="actionIcon" class="my-2">
          <v-icon v-if="!disabled" dense>{{ actionIcon }}</v-icon>
        </v-list-item-action>

      </v-list-item>
    </template>

    <template v-slot:default>
      <v-form
        ref="form"
        v-model="isValid"
        data-test="editableListItemForm"
        lazy-validation
        @submit.prevent="handleSubmit"
      >
        <slot>
          <v-card flat>
            <v-card-title>{{ title }}</v-card-title>

            <v-card-text>
              <p v-if="text !== null">{{ text }}</p>

              <v-textarea
                v-if="inputType === 'textarea'"
                v-model="fieldValue"
                :errorMessages="fieldError"
                :placeholder="placeholder"
                :rules="validation"
                auto-grow
                autofocus
                class="mt-1"
                name="editable-value"
                outlined
                rows="2"
                single-line
              ></v-textarea>

              <v-select
                v-else-if="inputType === 'select'"
                v-model="fieldValue"
                :errorMessages="fieldError"
                :items="items"
                :placeholder="placeholder"
                :rules="validation"
                autofocus
                class="mt-1"
                name="editable-value"
                outlined
                single-line
              ></v-select>

              <v-date-picker
                v-else-if="inputType === 'date'"
                v-model="fieldValue"
                :min="(new Date()).toISOString()"
                flat
                full-width
                name="editable-value"
                no-title
                show-week
              ></v-date-picker>

              <v-text-field
                v-else
                v-model="fieldValue"
                :errorMessages="fieldError"
                :placeholder="placeholder"
                :rules="validation"
                autofocus
                class="mt-1"
                name="editable-value"
                outlined
                single-line
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                name="cancel"
                small
                text
                @click="handleCancel"
              >{{ cancelLabel }}</v-btn>
              <v-btn
                color="primary"
                name="submit"
                small
                type="submit"
              >{{ submitLabel }}</v-btn>
            </v-card-actions>
          </v-card>
        </slot>
      </v-form>
    </template>

  </v-dialog>
</template>

<script>
import editableListItem from '@/mixins/editableListItem';
import formMixin from '@/mixins/form';

/**
 * An editable list item.
 *
 * This list item displays the data, when clicked, a dialog is opened with an editable field.
 *
 * @see https://vuetifyjs.com/en/components/lists/
 */
export default {
  name: 'EditableListItem',

  mixins: [formMixin, editableListItem],

  props: {
    // =============================================================================================
    // Dialog properties ( optional )
    // =============================================================================================
    // max dialog width
    maxWidth: {
      type: Number,
      required: false,
      default: 500,
    },

    // =============================================================================================
    // Form properties ( optional )
    // =============================================================================================
    title: {
      type: String,
      required: false,
      default: '',
    },
    subTitle: {
      type: String,
      required: false,
      default: null,
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
    cancelLabel: {
      type: String,
      required: false,
      default: 'Annuleren',
    },
    submitLabel: {
      type: String,
      required: false,
      default: 'Opslaan',
    },
    // input field validation rules
    validation: {
      type: [Array, String],
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      /**
       * @type {boolean}
       */
      isDialogOpen: false,
    };
  },

  computed: {
    /**
     * @type {boolean}
     */
    hasError() {
      return this.fieldError !== undefined;
    },

    /**
     * The error messages for the input field.
     *
     * @type {array<string>}
     */
    fieldError() {
      return this.errorBag[this.property];
    },
  },

  watch: {
    /**
     * Update the fieldValue with dataValue whe the dialog opens.
     */
    isDialogOpen(state) {
      if (state === true) {
        this.fieldValue = this.dataValue;
      }
    },
  },

  methods: {
    /**
     * Reset the form and close the dialog.
     *
     * @return {void}
     */
    handleCancel() {
      this.reset();
      this.errorBag[this.property] = undefined;

      this.closeDialog();
    },

    /**
     * Update the given model with the entered data and close the dialog.
     *
     * @return {void}
     */
    handleSubmit() {
      this.errorBag[this.property] = undefined;
      if (this.validate() === false) {
        return;
      }

      this.submit();
      this.closeDialog();
    },

    /**
     * Close the dialog.
     */
    closeDialog() {
      this.isDialogOpen = false;
    },
  },
};
</script>
