<template>
  <v-app>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col class="leftPane hidden-xs-only"></v-col>
          <v-col lg="5" md="7" sm="10" xl="4" xs="12">
            <v-slide-x-reverse-transition mode="out-in">
              <router-view/>
            </v-slide-x-reverse-transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <AppMessage></AppMessage>

  </v-app>
</template>

<script>
import AppMessage from '@/components/layout/AppMessage.vue';

/**
 * A split screen layout.
 *
 * This layout has two vertical columns, the column on the left is empty, the column on the right
 * displays the view defined by the router.
 */
export default {
  name: 'SplitScreenLayout',

  components: { AppMessage },
};
</script>

<style lang="sass">
.leftPane
  background: url('~@/assets/bg/building-blueprint.webp') no-repeat
  background-size: cover
  height: 100vh
</style>
