import AbstractModel from '@/model/AbstractModel';

/**
 */
export default {
  data() {
    return {
      /**
       * Model constructor.
       *
       * This property is used to identify the database model.
       * You MUST overwrite this property with the model constructor.
       *
       * data() {
       *   return {
       *     modelClass: Model,
       *   };
       * },
       *
       * @abstract
       * @constructor
       * @type {Function} the model constructor
       */
      modelClass: AbstractModel,
    };
  },

  methods: {
    /**
     * Find data by ID.
     *
     * First the local database is queries for the data when the data is not found the remote
     * API is queried.
     *
     * @param {string|number} id
     * @return {AbstractModel} The found data.
     */
    async find(id) {
      this.isLoading = true;

      let data = this.$store.getters[this.modelClass.namespace('find')](id);
      if (data === null) {
        // query the remote API
        await this.$store.dispatch(this.modelClass.namespace('load'), { parameters: { id } });
        data = this.$store.getters[this.modelClass.namespace('find')](id);
      }

      // eslint-disable-next-line new-cap,no-multi-assign
      const model = new this.modelClass();
      model.dto = data;

      this.isLoading = false;

      return model;
    },

    /**
     * @param {object} criteria
     * @return {Promise<AbstractModel[]>} The found data.
     */
    async findBy(criteria) {
      // TODO implement
      console.log(criteria);
    },

    /**
     * @param {object} data
     * @return {Promise<string|number>} The effected record ID.
     */
    async save(data) {
      this.isLoading = true;
      // eslint-disable-next-line new-cap,no-multi-assign
      const model = new this.modelClass();
      model.dto = data;
      let id = model.$getIndexIdFromAttributes();
      let saveAction = 'persist';
      let payload = model.dto;
      let parameters = {};

      // if there is no ID, the data is considered new
      if (id !== null) {
        saveAction = this.modelClass.updateMethod.toLowerCase();
        if (this.modelClass.updateMethod === AbstractModel.PATCH) {
          parameters = { id };
          payload = model.mergePatch;
        }
      }

      // save the data; after saving, the data will have an ID
      const items = await this.$store.dispatch(
        this.modelClass.namespace(saveAction),
        { parameters, payload },
      );
      if (id === null) {
        const newModel = items[this.modelClass.entity][0];
        id = newModel.$getIndexIdFromAttributes();
      }

      this.isLoading = false;

      return id;
    },

    /**
     * @param {object} data
     * @return {Promise<void>}
     */
    async remove(data) {
      this.isLoading = true;
      // eslint-disable-next-line new-cap,no-multi-assign
      const model = new this.modelClass();
      model.dto = data;

      await this.$store.dispatch(
        this.modelClass.namespace('remove'),
        { parameters: { id: model.$getIndexIdFromAttributes() } },
      );

      this.isLoading = false;
    },
  },
};
