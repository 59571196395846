/**
 * API resource DTO.
 */
export default class ApiResource {
  /**
   * The loaded data.
   *
   * @type {object|array<object>}
   */
  data;

  /**
   * The number of total available items.
   *
   * @type {number|undefined}
   */
  total;

  constructor(data, total) {
    this.data = data;
    this.total = total;
  }
}
