import { POST } from '@/services/http';
import { parse, removeToken, setToken } from '@/services/jwt';

/**
 * Authentication session store module.
 *
 * This module is used to store session data about the authenticated user.
 */
export default {
  namespaced: true,

  state: () => ({
    /**
     * @type {?string} The authorization token
     */
    token: null,

    id: null,
    companyId: null,
    email: null,
    /**
     * @type {array<string>}
     */
    roles: [],
  }),

  mutations: {
    /**
     * @param state
     * @param {?string} payload
     * @return {void}
     */
    UPDATE_TOKEN(state, payload) {
      if (
        payload === null
        || (typeof payload === 'string' && payload.length > 1)
      ) {
        state.token = payload;
      }
    },

    /**
     * @param state
     * @param {{email: string, firstName: string, lastName: string}} payload
     * @return {void}
     */
    UPDATE_USER(state, payload) {
      const {
        companyId,
        email,
        id,
        roles,
      } = payload;

      state.id = id || null;
      state.companyId = companyId || null;
      state.email = email || null;
      state.roles = roles || [];
    },
  },

  getters: {
    /**
     * @return {boolean} True if there is an auth token; false otherwise
     */
    hasSession: (state) => state.token !== null,

    /**
     * @return {object} The session token payload.
     */
    payload: (state) => {
      const { token } = state;
      if (token === null) {
        return null;
      }

      const { payload } = parse(token);

      return payload;
    },

    /**
     * @return {number}
     */
    expirationTime: (_, getters) => {
      // the expiration time is in seconds, Date.now uses ms
      const expiration = Math.round((getters.payload?.exp ?? 0) * 1000);
      if (Number.isInteger(expiration) === false || expiration <= 0) {
        return 0;
      }

      const timeLeft = expiration - Date.now();
      if (timeLeft <= 0) {
        return 0;
      }

      return timeLeft;
    },

    /**
     * @returns {Array<string>}
     */
    roles: (state) => state.roles || [],

    /**
     * @return {?string} The User ID.
     */
    userId: (state) => state.id,

    /**
     * @return {?string} The company ID of the user.
     */
    companyId: (state) => state.companyId,
  },

  actions: {
    /**
     * Try to authenticate with the given credentials.
     *
     * @param context
     * @param {{email: string, password: string}} credentials
     * @return {Promise<boolean>} True if the user is authenticated, false otherwise.
     */
    async authenticateWithUsernameAndPassword({ dispatch, rootState }, credentials) {
      const { apis } = rootState.settings;
      try {
        const { body } = await POST(`${apis.login_api}/session`, credentials);
        const sessionToken = body?.token ?? null;

        dispatch('createSession', sessionToken);
      } catch (error) {
        dispatch('destroySession');

        // rethrow the error, this way higher level code can handle the error
        throw error;
      }

      return true;
    },

    /**
     * Impersonate a user by their email.
     *
     * @param context
     * @param {{email: string}} credentials
     * @return {Promise<boolean>} True if the user is authenticated, false otherwise.
     */
    async impersonate({ dispatch, rootState }, credentials) {
      const { apis } = rootState.settings;
      const { body } = await POST(`${apis.login_api}/impersonate`, credentials);
      const { token } = body;

      dispatch('destroySession'); // first destroy the old session
      dispatch('createSession', token);
    },

    /**
     * Parse and store the token. This action is executed when the authentication succeeds or at
     * least when we have a token.
     *
     * @param context
     * @param {string} token
     * @return {Promise<void>}
     */
    async createSession({ commit, dispatch, state }, token) {
      const { payload } = parse(token);

      setToken(token);
      commit('UPDATE_TOKEN', token);
      commit('UPDATE_USER', payload);

      // load the user account data
      dispatch('database/user/load', { parameters: { id: state.id } }, { root: true });
    },

    /**
     * Destroy the authentication session.
     *
     * @param context
     * @return {void}
     */
    destroySession({ commit, dispatch }) {
      // forget the token (localstorage)
      removeToken();
      // unset the session
      commit('UPDATE_TOKEN', null);
      commit('UPDATE_USER', {});
      // remove all data stored in the local database
      dispatch('database/deleteAll', null, { root: true });
      // reset all VueX modules
      dispatch('settings/reset', null, { root: true });
      commit('openApi/TOGGLE_LOADED', null, { root: true });
    },
  },
};
