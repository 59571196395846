/**
 * EditableListItem helper mixin.
 *
 * Use this mixin in ListItems to allow them to change the value of an object.
 *
 * This component assumes that the value is always encapsulated in an object, retrieved from the
 * state store, or at least that it is not the object, but the state store that needs to be updated.
 * So, v-model is not an option. Instead, this mixin emits a submit event with a patch to update the
 * state store.
 *
 * <v-list-item :disabled="disabled"  @click="submit">
 *     <v-list-item-icon v-if="itemIcon">
 *       <v-icon>{{ itemIcon }}</v-icon>
 *     </v-list-item-icon>
 *     <v-list-item-content>
 *       <v-list-item-title>
 *         <slot>{{ placeholder }}</slot>
 *       </v-list-item-title>
 *     </v-list-item-content>
 *     <v-list-item-action v-if="actionIcon">
 *       <v-icon v-if="!disabled">{{ actionIcon }}</v-icon>
 *     </v-list-item-action>
 * </v-list-item>
 */
export default {
  props: {
    // =============================================================================================
    // Global component properties
    // =============================================================================================
    // the data object
    dataModel: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    // the property in the data model
    property: {
      type: String,
      required: false,
      default: '',
    },
    // =============================================================================================
    // List item properties ( optional )
    // =============================================================================================
    // list action icon
    actionIcon: {
      type: String,
      required: false,
      default: 'mdi-pencil',
    },
    // disable the list item
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    // list icon
    itemIcon: {
      type: String,
      required: false,
      default: null,
    },
    // list item and input placeholder ( optional )
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    // =============================================================================================
    // Form input properties ( optional )
    // =============================================================================================
    // the input type: text-field, textarea, select
    inputType: {
      type: String,
      required: false,
      default: 'text-field',
    },
    // the items used when the inputType is 'select'
    items: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },

  data() {
    return {
      /**
       * The editable field value.
       *
       * Use this property as v-model for the input field.
       *
       * @type {any}
       */
      fieldValue: undefined,
    };
  },

  computed: {
    /**
     * The value of the dataModel property.
     *
     * This value is used as the initial value of the fieldValue.
     *
     * @type {any}
     */
    dataValue() {
      const { dataModel, property } = this;

      return dataModel[property] ? dataModel[property] : '';
    },

    /**
     * The translated dataValue that can be used to display a dataValue.
     *
     * The data text is based on the input type.
     *
     * @return {any}
     */
    dataText() {
      const { dataValue, inputType, items } = this;
      if (dataValue === undefined || dataValue === null || dataValue === '') {
        return '';
      }

      if (inputType === 'select') {
        const selected = items.find((item) => item.value === dataValue);
        return selected ? selected.text : dataValue;
      }

      if (inputType === 'date') {
        return (new Date(dataValue)).toLocaleDateString();
      }

      return dataValue;
    },
  },

  mounted() {
    this.fieldValue = this.dataValue;
  },

  methods: {
    /**
     * Submit the dataModel changes.
     *
     * Create a patch object and emit a submit event with the patch.
     *
     * @return {void}
     */
    submit() {
      const { property, fieldValue, dataModel } = this;
      const patch = { ...dataModel };
      patch[property] = fieldValue;
      this.$emit('submit', patch);
    },
  },
};
