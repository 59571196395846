import databaseModuleFactory from '@/store/databaseModuleFactory';

export default (entity, api) => {
  const databaseResource = databaseModuleFactory(entity, api);

  return {
    ...databaseResource,

    actions: {
      ...databaseResource.actions,

      /**
       * Execute a remote procedure call.
       *
       * @param _
       * @param {string} action
       * @param {any} payload
       * @param {RequestInit} options
       * @return {string|number|{}|null}
       */
      async rpc(_, { action, payload = {}, options = { method: 'POST' } }) {
        const { data } = await api.requestEndpoint(`/${action}`, payload, options);

        return data;
      },
    },
  };
};
