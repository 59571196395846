import ServerError from '@/services/http/errors/ServerError';

/**
 * A HTTP 404 Not Found error.
 */
export default class NotFoundError extends ServerError {
  /**
   * @param {Response|string|undefined} response
   */
  constructor(response) {
    super(response);

    if ((response instanceof Response) === false) {
      this.statusCode = 404;
      this.message = 'Not Found';
    }
  }
}
