<template>
  <router-view/>
</template>

<script>
import redirect from '@/mixins/redirect';
import translateMixin from '@/mixins/translate';
import { getToken } from '@/services/jwt';

/**
 * The application base.
 *
 * This component is the root component of this app and is always loaded first. The router and the
 * router-view play together,to display the desired view.
 *
 * @see https://router.vuejs.org/api/#router-view
 *
 * This application uses an extra layer for the layout which is configured in the router.
 *
 * /////////      ////////////      //////////      ////////////////
 * // App //  =>  // Layout //  =>  // View //  =>  // Components //
 * /////////      ////////////      //////////      ////////////////
 *
 * @see https://vuetifyjs.com/en/components/application/
 */
export default {
  name: 'App',

  mixins: [redirect, translateMixin],

  data() {
    return {
      // @see translateMixin.translateKeyPrefix
      translateKeyPrefix: 'app',
    };
  },

  async created() {
    // apply the app settings
    try {
      await this.$store.dispatch('settings/apply');
    } catch (error) {
      this.$store.commit('messages/add', this._('settings_load_error'));
    }

    // load all used API specs
    Promise.all(
      Object.values(this.$store.getters['settings/apis']).map(
        (url) => this.$store.dispatch('openApi/loadSpecs', `${url}/docs.json`),
      ),
    ).catch((error) => {
      this.$store.commit('messages/add', `${this._('open_api_spec_load_error')} ${error?.message}`);
    });

    const sessionToken = getToken();
    if (sessionToken === null) {
      return;
    }

    try {
      // reload the session
      await this.$store.dispatch('session/createSession', sessionToken);
      this.redirectAfterLogin();
    } catch (_) {
      this.$store.commit('messages/add', this._('session_restore_errorMsg'));

      this.redirectAfterLogout();
    }
  },
};
</script>
