import messageMixing from '@/mixins/message';
import repositoryMixin from '@/mixins/repository';
import translateMixin from '@/mixins/translate';
import UnauthorizedError from '@/services/http/errors/UnauthorizedError';
import ForbiddenError from '@/services/http/errors/ForbiddenError';
import NotFoundError from '@/services/http/errors/NotFoundError';
import UnprocessableEntityError from '@/services/http/errors/UnprocessableEntityError';
import BadRequestError from '@/services/http/errors/BadRequestError';

/**
 * Form handler helper mixin.
 *
 * Use this mixin in components that are responsible for loading and saving data. Usually these are
 * view components that use Form components.
 *
 * Example:
 * <AcmeForm v-model="dto" @submit="persistForm"></AcmeForm>
 */
export default {
  mixins: [
    messageMixing,
    repositoryMixin,
    translateMixin,
  ],

  data() {
    return {
      /**
       * True when form data is loaded and ready to be used in forms.
       *
       * @type {boolean}
       */
      isFormLoaded: false,

      /**
       * True when saving or loading the data.
       *
       * @type {boolean}
       */
      isLoading: false,

      /**
       * When Model properties are used as input v-model values, the model reference will illegally
       * update the values in the state store, thus updating the entire app. To prevent this, a DTO
       * is made of the model.
       *
       * @see AbstractModel.dto
       *
       * @type {object}
       */
      dto: {},

      /**
       * Error-bag for external errors.
       *
       * @type {{string: string|array<string>}}
       */
      errorBag: {},
    };
  },

  methods: {
    /**
     * Load form data and hydrate the form fields with the values of the data model.
     *
     * @param {AbstractModel|object} data
     * @return {void}
     */
    loadFormData(data) {
      let dto = data;
      // noinspection PointlessBooleanExpressionJS
      if ((data instanceof this.modelClass) === true) {
        dto = data.dto;
      }

      this.isFormLoaded = true;

      this.dto = JSON.parse(JSON.stringify(dto));
    },

    /**
     * Find data by ID, if not found, fetch it and hydrate the form with it.
     *
     * @param {string|number} id
     * @return {Promise<void>}
     */
    async loadForm(id) {
      this.isLoading = true;

      try {
        const model = await this.find(id);
        this.loadFormData(model);
      } catch (error) {
        this.handleFormError(error);
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * @param {AbstractModel|object} data
     * @return {Promise<string|number|null>}
     */
    async saveForm(data) {
      this.isLoading = true;

      try {
        const id = await this.save(data);
        await this.loadForm(id);
        this.isFormLoaded = false;
      } catch (error) {
        this.handleFormError(error);
      } finally {
        this.isLoading = false;
      }
      return null;
    },

    /**
     * @param {AbstractModel|object} data
     * @return {Promise<void>}
     */
    async deleteForm(data) {
      this.isLoading = true;
      try {
        await this.remove(data);
        this.isFormLoaded = false;
      } catch (error) {
        this.handleFormError(error);
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * @return {void}
     */
    resetForm() {
      this.dto = {};
      this.errorBag = {};
      this.isLoading = false;
      this.isFormLoaded = false;
    },

    /**
     * @param {Error} error
     * @return {void}
     */
    handleFormError(error) {
      if (error instanceof UnprocessableEntityError) {
        this.errorBag = { ...this.errorBag, ...error.getViolations() };
      } else if (error instanceof BadRequestError) {
        this.displayMessage(this.translate('mixins.form_handle.bad_request'));
      } else if (error instanceof UnauthorizedError) {
        this.displayMessage(this.translate('mixins.form_handle.unauthorized'));
      } else if (error instanceof ForbiddenError) {
        this.displayMessage(this.translate('mixins.form_handle.forbidden'));
      } else if (error instanceof NotFoundError) {
        this.displayMessage(this.translate('mixins.form_handle.not_found'));
      } else {
        this.displayMessage(this.translate('mixins.form_handle.server_error'));
      }
    },
  },
};
