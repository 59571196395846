/**
 * Form helper mixin.
 *
 * Use this mixin in form components.
 * A form component is a component with the `v-form` component.
 */
export default {
  props: {
    /**
     * Form components always have a v-model attribute that expects an object (model).
     *
     * Example: <AcmeForm v-model="formData".../>
     */
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    /**
     * Form loading state.
     */
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * Error-bag for external errors.
     *
     * This error bag is an object where the object key matches the form field name and the object
     * value are the error messages. An error message can be a `String` or `Array` of `String`s.
     */
    errorBag: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      /**
       * This form model contains properties which should match with the form fields.
       *
       * @type {Object}
       */
      model: this.value ?? {},
      /**
       * Form validity state.
       *
       * @type {boolean}
       */
      isValid: true,
      /**
       * Default validation rules.
       *
       * @type {object}
       */
      defaultRules: {
        required: (v) => !!v || 'Dit veld is verplicht',
        email: (v) => /.+@.+/.test(v) || 'Vul een geldig e-mailadres in',
      },
    };
  },

  watch: {
    /**
     * Update the internal model then the v-model changes.
     */
    value() {
      this.model = this.value;
    },
  },

  methods: {
    /**
     * Validates all registered inputs. Returns true if successful and false if not
     *
     * You need to add a `ref="form"` attribute to the form components.
     * Example: <v-form refs="form">
     *
     * @return {boolean}
     */
    validate() {
      const { form } = this.$refs;
      if (typeof form?.validate === 'function') {
        return form.validate();
      }

      return false;
    },

    /**
     * Resets validation of all registered inputs without modifying their state.
     *
     * You need to add a `ref="form"` attribute to the form components.
     * Example: <v-form refs="form">
     *
     * @return {void}
     */
    resetValidation() {
      const { form } = this.$refs;
      if (typeof form?.resetValidation === 'function') {
        form.resetValidation();
      }
    },

    /**
     * Resets the form to its initial state.
     *
     * You need to add a `ref="form"` attribute to the form components.
     * Example: <v-form refs="form">
     *
     * @return {void}
     */
    reset() {
      const { form } = this.$refs;
      if (typeof form?.reset === 'function') {
        form.reset();
      }

      this.model = this.value;
      this.isValid = true;

      this.$emit('reset');
    },

    /**
     * Use the method to submit forms. It will emit the submit event to its parent component.
     *
     * <v-form @submit.prevent="handleSubmit".../>
     *
     * @return {void}
     */
    submit() {
      if (this.validate() !== true) {
        return;
      }

      // sync the v-model value
      this.$emit('input', this.model);
      // trigger the form submit event
      this.$emit('submit', this.model);
    },
  },
};
