import BadRequestError from '@/services/http/errors/BadRequestError';
import ClientError from '@/services/http/errors/ClientError';
import ForbiddenError from '@/services/http/errors/ForbiddenError';
import NotFoundError from '@/services/http/errors/NotFoundError';
import ServerError from '@/services/http/errors/ServerError';
import UnauthorizedError from '@/services/http/errors/UnauthorizedError';
import UnprocessableEntityError from '@/services/http/errors/UnprocessableEntityError';
import { getToken } from '@/services/jwt';

/**
 * @type {string}
 */
const jsonMimeType = 'application/json';
const jsonLdMimeType = 'application/ld+json';
const jsonProblemMimeType = 'application/problem+json';
const jsonPatchMimeType = 'application/merge-patch+json';

/**
 * Add default headers to the request options.
 *
 * @param {RequestInit} options
 * @return {RequestInit&{headers: Headers}}
 */
function addHeaders(options) {
  const token = getToken();
  let headers = options.headers || {};
  headers = new Headers(headers);

  if (headers.get('Authorization') === null && token !== null) {
    headers.set('Authorization', `bearer ${token}`);
  }

  if (headers.get('Accept') === null) {
    headers.set('Accept', jsonMimeType);
  }

  // FormData sets its own content-type headers, do nothing
  if (
    headers.get('Content-Type') === null
    && options.body !== undefined
    && !(options.body instanceof FormData)
  ) {
    headers.set('Content-Type', jsonMimeType);
  }

  return {
    ...options,
    headers,
  };
}

/**
 * Create a specific or global client error based on the response status.
 *
 * @param {Response} response
 * @param {Object} body Json decoded body object.
 * @return {
 *   BadRequestError
 *   | UnauthorizedError
 *   | ForbiddenError
 *   | NotFoundError
 *   | UnprocessableEntityError
 *   | ClientError
 * }
 */
function createClientError(response, body) {
  const { status } = response;

  switch (status) {
    case 400:
      return new BadRequestError(response, body);

    case 401:
      return new UnauthorizedError(response);

    case 403:
      return new ForbiddenError(response);

    case 404:
      return new NotFoundError(response);

    case 422:
      return new UnprocessableEntityError(response, body);

    default:
      return new ClientError(response);
  }
}

/**
 * @param {Object} parameters
 * @param {?string} prefix
 * @return {string}
 */
function toQueryString(parameters, prefix = null) {
  const queryParameters = [];
  let params = { ...parameters };
  if (params.filters && typeof params.filters === 'object') {
    const filters = { ...params.filters };
    delete params.filters;
    params = { ...params, ...filters };
  }

  Object.entries(params).forEach(([name, value]) => {
    const key = prefix ? `${prefix}[${name}]` : name;
    queryParameters.push(
      (value !== null && typeof value === 'object')
        ? toQueryString(value, key) : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    );
  });

  return queryParameters.join('&');
}

/**
 * @param {string} baseUrl
 * @param {{any}} parameters
 * @returns {string}
 */
function createEndpoint(baseUrl, parameters = {}) {
  let endpoint = baseUrl;
  const params = { ...parameters };
  if (Object.keys(params).length === 0) {
    // there are no parameters to modify the endpoint with
    return endpoint;
  }

  if (params?.id !== undefined && params?.id !== null) {
    // add the ID to the endpoint
    endpoint = `${endpoint}/${params.id}`;
    delete params.id;
  }

  // find all template variables in the endpoint; "example.com/videos/{VID}/comments"
  const pathVariables = endpoint.match(/{([^}]+)}/g);
  if (pathVariables !== null) {
    pathVariables.forEach((pathVariable) => {
      // strip { and } from the pathVariable
      const paramName = pathVariable.slice(1, -1);
      if (params[paramName] !== undefined) {
        // replace the template variables with the parameters
        endpoint = endpoint.replace(pathVariable, params[paramName]);
        delete params[paramName];
      }
    });
  }

  const queryString = toQueryString(params);
  if (queryString.length > 0) {
    endpoint = `${endpoint}?${queryString}`;
  }

  return endpoint;
}

/**
 * Send a request and return the fetched json.
 *
 * @param {string} url
 * @param {RequestInit} options
 * @return {Promise<{response: Response, body: any}|{response: Response}>}
 */
async function fetchJson(url, options = {}) {
  // Send the request
  const response = await fetch(url, addHeaders(options));
  const { headers, status } = response;
  const contentType = headers.get('Content-Type');

  if (status === 204) {
    // success; no body expected
    return Promise.resolve({ response });
  }

  if (
    status >= 500
    || contentType === null
    || (contentType.includes(jsonLdMimeType) === false
      && contentType.includes(jsonMimeType) === false
      && contentType.includes(jsonProblemMimeType) === false
      && contentType.includes(jsonPatchMimeType) === false)
  ) {
    // not the result we expected, there is no guarantee that the response body can be decoded.
    return Promise.reject(new ServerError(response));
  }

  // the body can only be read once
  const body = await response.json();

  if (status >= 200 && status < 300) {
    // success
    return Promise.resolve({ response, body });
  }

  if (status >= 400 && status < 500) {
    // client errors can contain detailed error messages, use the body to enrich the error message
    return Promise.reject(createClientError(response, body));
  }

  // unrecognised error
  return Promise.reject(new Error('Unknown error.'));
}

/**
 * Send a GET request and return the json decoded response body.
 *
 * @param {string} url The full URL including protocol
 * @param {RequestInit} options
 * @return {Promise<{response: Response, body: any}|{response: Response}>}
 */
async function GET(url, options = {}) {
  return fetchJson(url, {
    ...options,
    method: 'GET',
  });
}

/**
 * Send a POST request with a payload and return the json decoded response body.
 *
 * @param {string} url The full URL including protocol.
 * @param {Object} payload
 * @param {RequestInit} options
 * @return {Promise<{response: Response, body: any}|{response: Response}>}
 */
async function POST(url, payload, options = {}) {
  return fetchJson(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

/**
 * Send a PATCH request with a payload and return the response.
 *
 * @param {string} url The full URL including protocol.
 * @param {Object} payload
 * @param {RequestInit} options
 * @return {Promise<{response: Response, body: *}|{response: Response}>}
 */
async function PATCH(url, payload, options = {}) {
  return fetchJson(url, {
    ...options,
    headers: { 'Content-Type': jsonPatchMimeType },
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
}

/**
 * Send a DELETE request and return the response.
 *
 * @param {string} url The full URL including protocol.
 * @param {RequestInit} options
 * @return {Promise<{response: Response, body: *}|{response: Response}>}
 */
async function DELETE(url, options = {}) {
  return fetchJson(url, {
    ...options,
    method: 'DELETE',
  });
}

/**
 * Send a PUT request and return the response.
 *
 * @param {string} url The full URL including protocol.
 * @param payload
 * @param {RequestInit} options
 * @return {Promise<{response: Response, body: *}|{response: Response}>}
 */
async function PUT(url, payload, options = {}) {
  return fetchJson(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(payload),
  });
}

export {
  jsonMimeType,
  jsonLdMimeType,
  jsonPatchMimeType,
  jsonProblemMimeType,
  fetchJson,
  createEndpoint,
  DELETE,
  GET,
  POST,
  PATCH,
  PUT,
};
