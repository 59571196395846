<template>
  <v-row justify="center" no-gutters>
    <v-col sm="12" md="8" lg="6" xl="4">
      <v-row>
        <v-col>
          <v-card outlined>

            <v-img
              :src="require('@/assets/undraw/undraw_Private_data_re_4eab.svg')"
              alt=""
              class="shrink my-5"
              transition="scale-transition"
              contain
              height="200"
            />

            <v-card-title>
              <span>{{ _('your_account_data') }}</span>
              <v-divider inset></v-divider>
            </v-card-title>

            <v-list>

              <v-list-item :to="{ name: 'PersonalData' }">
                <v-list-item-icon>
                  <v-icon>mdi-text-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ _('personal_data') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-list-item :to="{ name: 'ChangePassword' }">
                <v-list-item-icon>
                  <v-icon>mdi-form-textbox-password</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ _('change_password') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-list-item :to="{ name: 'AccountSession' }">
                <v-list-item-icon>
                  <v-icon>mdi-account-details-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ _('account_session') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

            </v-list>

          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import translateMixin from '@/mixins/translate';

export default {
  name: 'AccountView',

  mixins: [
    translateMixin,
  ],

  data() {
    return {
      // @see translateMixin.translateKeyPrefix
      translateKeyPrefix: 'views.account.account',
    };
  },
};
</script>
