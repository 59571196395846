import {
  DELETE,
  fetchJson,
  GET,
  PATCH,
  POST,
  PUT,
  createEndpoint,
} from '@/services/http';
import ApiResource from '@/services/api/ApiResource';

/**
 * Json API resource factory.
 *
 * Use this factory to create resource objects which can communicate with the API.
 *
 * @param {string} baseUrl The API resource endpoint.
 * @return {{
 *  createOne({any}, {any}, RequestInit): Promise<ApiResource>,
 *  patchOne({any}, {any}, RequestInit): Promise<ApiResource>,
 *  updateOne({any}, {any}, RequestInit): Promise<ApiResource>,
 *  fetchCollection({any}, RequestInit): Promise<ApiResource>,
 *  removeOne({any}, RequestInit): Promise<void>,
 *  fetchOne({any}, RequestInit): Promise<ApiResource>
 * }}
 */
export default (baseUrl) => ({
  /**
   * @param {{any}} parameters
   * @param {{any}} payload
   * @param {RequestInit} options
   *
   * @return {Promise<ApiResource>}
   */
  async createOne(parameters, payload, options = {}) {
    const url = createEndpoint(baseUrl, parameters);
    const { body } = await POST(url, payload, options);

    return new ApiResource(body);
  },

  /**
   * @param {{any}} parameters
   * @param {{any}} payload
   * @param {RequestInit} options
   *
   * @return {Promise<ApiResource>}
   */
  async patchOne(parameters, payload, options = {}) {
    const url = createEndpoint(baseUrl, parameters);
    const { body } = await PATCH(url, payload, options);

    return new ApiResource(body);
  },

  /**
   * @param {{any}} parameters
   * @param {{any}} payload
   * @param {RequestInit} options
   *
   * @return {Promise<ApiResource>}
   */
  async updateOne(parameters, payload, options = {}) {
    const url = createEndpoint(baseUrl, parameters);
    const { body } = await PUT(url, payload, options);

    return new ApiResource(body);
  },

  /**
   * @param {{any}} parameters
   * @param {RequestInit} options
   *
   * @return {Promise<ApiResource>}
   */
  async fetchCollection(parameters, options = {}) {
    const url = createEndpoint(baseUrl, parameters);
    const { body } = await GET(url, options);

    return new ApiResource(body);
  },

  /**
   * @param {{any}} parameters
   * @param {RequestInit} options
   *
   * @return {Promise<ApiResource>}
   */
  async fetchOne(parameters, options = {}) {
    const url = createEndpoint(baseUrl, parameters);
    const { body } = await GET(url, options);

    return new ApiResource(body);
  },

  /**
   * @param {{any}} parameters
   * @param {RequestInit} options
   *
   * @return {Promise<void>}
   */
  async removeOne(parameters, options = {}) {
    const url = createEndpoint(baseUrl, parameters);
    await DELETE(url, options);
  },

  /**
   * Send a file upload request and return the response body.
   *
   * The payload must be an instance of FormData.
   * const file = <File>...
   * const payload = new FormData();
   * payload.append('file', file);
   *
   * @param {string|number} id
   * @param {FormData} payload
   * @param {RequestInit} options
   *
   * @return {Promise<ApiResource>}
   */
  uploadOne: async ({ id, payload }, options = {}) => {
    const { body } = await fetchJson(createEndpoint(baseUrl, { id }), {
      ...options,
      ...{ method: 'POST', body: payload },
    });

    return new ApiResource(body);
  },
});
