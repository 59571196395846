import nl from 'vuetify/lib/locale/nl';

export default {
  ...nl,

  app: {
    session_restore_errorMsg: 'Uw sessie kon niet hersteld worden. Log opnieuw in.',
    open_api_spec_load_error: 'API-specificatie kan niet geladen worden door fout: ',
    settings_load_error: 'Instellingen zijn niet hersteld. De standaard instellingen worden gebruikt.',
  },

  components: {
    account: {
      change_password_form: {
        new_password: 'Nieuw wachtwoord',
        repeat_password: 'Herhaal wachtwoord',
        passwords_need_to_be_equal: 'Deze wachtwoorden zijn niet hetzelfde',
        password_is_required: 'Dit veld is verplicht',
        change_password: 'Wachtwoord wijzigen',
      },

      login_form: {
        // title
        login_with_username_and_password: 'Login',
        // field labels
        enter_username: 'E-mailadres',
        enter_password: 'Wachtwoord',
        // field hints
        use_the_username_known_by_ib: 'Vul hier het e-mailadres in waarmee u bij IB bent geregistreerd.',
        // validations
        username_is_required: 'Dit veld is verplicht',
        username_is_invalid: 'Vul een geldig e-mailadres in',
        password_is_required: 'Dit veld is verplicht',
        // buttons
        forgot_my_password: 'Wachtwoord vergeten?',
        login: 'Inloggen',
      },

      password_recovery_form: {
        // title
        request_password_recovery: 'Nieuw wachtwoord aanvragen',
        enter_the_username_known_by_ib: 'Vul hier het e-mailadres in waarmee u bij IB bent geregistreerd.',
        // field labels
        enter_username: 'E-mailadres',
        // validations
        username_is_required: 'Dit veld is verplicht',
        username_is_invalid: 'Vul een geldig e-mailadres in',
        // buttons
        login: 'Inloggen?',
        start_recovery: 'Reset aanvragen',
      },

      recovery_secret_redeem_form: {
        // title
        redeem_recovery_secret: 'Aanvragen verifiëren',
        // intro
        enter_received_secret: 'Vul hier de verificatiecode in die u ontvangen hebt.',
        // field labels
        enter_secret: 'Verificatie code',
        // validations
        secret_is_required: 'Dit veld is verplicht',
        secret_is_invalid: 'Vul een geldige code in',
        // buttons
        restart_recovery: 'Geen code ontvangen?',
        redeem_secret: 'Nieuw wachtwoord opgeven',
      },
    },

    article: {
      article_form: {
        article: 'Artikel',
        warning_delayed_article_update: 'Waarschuwing: Als u dit artikel wijzigt, wordt deze wijziging pas doorgevoerd na controle. Tot die tijd blijft het artikel hetzelfde als voorheen.',
        availability: 'Beschikbaarheid',
        description: 'Omschrijving',
        features: 'Eigenschappen',
        cancel: 'Annuleren',
        save: 'Opslaan',
      },

      article_feature_table: {
        name: 'Naam',
        value: 'Waarde',
        unit: 'Eenheid',
      },

      article_feature_form: {
        value: 'Waarde',
        unit: 'Eenheid',
        value_too_long: 'Feature waarde kan niet meer dan 10240 karakters bevatten',
        unit_too_long: 'Feature eenheid kan niet meer dan 64 karakters bevatten',
        cancel: 'Annuleren',
        save: 'Opslaan',
      },
    },

    product: {
      product_feature_table: {
        name: 'Naam',
        value: 'Waarde',
        unit: 'Eenheid',
      },
      product_feature_form: {
        value: 'Waarde',
        unit: 'Eenheid',
        value_too_long: 'Feature waarde kan niet meer dan 10240 karakters bevatten',
        unit_too_long: 'Feature eenheid kan niet meer dan 64 karakters bevatten',
        cancel: 'Annuleren',
        save: 'Opslaan',
      },
    },

    company: {
      address_form: {
        addresses: 'Vestigingsadres',

        type: 'Type adres',
        street_name: 'Straatnaam',
        house_nr: 'Huisnummer',
        postal_code: 'Postcode',
        location: 'Plaatsnaam',
        country: 'Land',
        phone_nr: 'Telefoonnummer',
        email: 'E-mailadres',

        delete: 'Verwijderen',
        reset: 'Annuleren',
        submit: 'Opslaan',

        street_name_too_long: 'Straatnaam kan niet meer dan 200 karakters bevatten',
        house_nr_too_long: 'Huisnummer kan niet meer dan 10 karakters bevatten',
        postal_code_too_long: 'Postcode kan niet meer dan 8 karakters bevatten',
        phone_nr_too_long: 'Telefoonnummer kan niet meer dan 20 karakters bevatten',
        email_too_long: 'E-mailadres kan niet meer dan 64 karakters bevatten',
        location_too_long: 'Plaatsnaam kan niet meer dan 64 karakters bevatten',
        value_is_required: 'Dit veld is verplicht',
      },

      contact_person_form: {
        employees: 'Medewerker',
        first_name: 'Voornaam',
        last_name: 'Achternaam',
        gender: 'Geslacht',
        phone_nr: 'Telefoonnummer',
        mobile_nr: 'Mobiel nummer',
        email: 'E-mailadres',
        primary_contact_for: 'Contactpersoon voor',
        function: 'Functieomschrijving',
        availability: 'Beschikbaarheid',
        delete: 'Verwijderen',
        cancel: 'Annuleren',
        submit: 'Opslaan',
        // validations
        first_name_too_long: 'Voornaam kan niet meer dan 200 karakters bevatten',
        last_name_too_long: 'Achternaam kan niet meer dan 200 karakters bevatten',
        phone_nr_too_long: 'Telefoonnummer kan niet meer dan 32 karakters bevatten',
        mobile_nr_too_long: 'Mobiel nummer kan niet meer dan 32 karakters bevatten',
        email_too_long: 'E-mailadres kan niet meer dan 64 karakters bevatten',
        function_too_long: 'Functieomschrijving kan niet meer dan 64 karakters bevatten',
        availability_too_long: 'Beschikbaarheid kan niet meer dan 500 karakters bevatten',
        value_is_required: 'Dit veld is verplicht',
      },

      logo_upload_form: {
        upload_logo: 'Upload nieuw logo',
      },

      opening_hours_form: {
        open_hours: 'Openingstijden',
        open_time: 'Open vanaf',
        close_time: 'Open tot',
        submit: 'Opslaan',
        reset: 'Annuleren',
        //
        time_invalid: 'Voer een geldige tijd in',
      },
    },

    dashboard: {
      company: {
        contact_persons: 'Medewerkers',
        addresses: 'Vestigingen',

        company_title: 'Controleer en beheer uw bedrijfsgegevens',
        company_subtitle: 'Onze diensten werken het beste wanneer uw bedrijfsgegevens up-to-date zijn.',

        company_visualisation_title: 'Hoe wordt uw bedrijf weergegeven',
        company_visualisation_subtitle: 'Een eerste indruk is belangrijk, ook online. Controleer hoe wij uw bedrijf weergeven.',

        edit_company: 'Bedrijfsgegevens beheren',
        edit_opening_hours: 'Openingstijden beheren',
        edit_logo: 'Bedrijfslogo wijzigen',
        edit_contact_person: 'Medewerkers beheren',
        edit_addresses: 'Vestigingen beheren',
      },

      article: {
        articles: 'Artikelen',
        articles_title: 'Controleer en beheer uw artikelen',
        articles_subtitle: 'TODO Korte uitleg over producten en artikelen',
        edit_articles: 'Artikelen beheren',

        products: 'Producten',
        products_title: 'Controleer en beheer uw producten',
        products_subtitle: 'TODO Korte uitleg over producten en artikelen',
        edit_products: 'Producten beheren',

        load_error: 'Serverfout. Probeer het later opnieuw of neem contact op met de beheerder.',
      },
    },

    layout: {
      app_bar: {
        logo_alt: 'IB the data company Logo',
      },

      app_navigation: {
        settings_label: 'Instellingen',
        collapse_navigation_drawer_label: 'Minimaliseer sidebar',
      },

      app_footer: {
        logo_alt: 'IB the data company Logo',

        contact_title: 'Contact',
        contact_sub_title: 'Aarzel niet om bij vragen of opmerkingen contact met ons op te nemen:',

        companyN_name: 'IB the data company',
        companyA_address: 'Fuutlaan 14J, 5613 AB Eindhoven',
        company_phone: '040 - 30 41 42 0',
        company_email: 'info@ib.nl',
        company_openHours: 'Openingstijden: 8:30 - 18:00',

        copyright: 'IB.NL • IB.NL is een product van IB the data company',
      },

      session_menu: {
        my_account: 'Account beheren',
        logout: 'Uitloggen',
        login_as: 'Inloggen als',

        // errors
        unauthorized: 'U bent niet geauthenticeerd om in te loggen als een andere gebruiker.',
        forbidden: 'U bent niet geautoriseerd om in te loggen als een andere gebruiker.',
        not_found: 'U probeert in te loggen als een onbekende gebruiker',
        bad_request: 'Inloggen mislukt: Request fout.',
        server_error: 'Inloggen mislukt: Onbekende fout.',
      },

      impersonate_form: {
        login_as: 'Inloggen als een klant',
        username: 'E-mailadres',
        use_your_ib_credentials: 'Gebruik het e-mailadres waarmee de klant kan inloggen',
        login: 'Inloggen',
      },
    },

    sku: {
      prefix_upload_form: {},

      config_form: {
        configuration: 'SKU Configuratie',
        new_sku_config: 'Maak een nieuwe configuratie om SKU nummers te genereren.',

        name: 'Naam',
        name_hint: 'Wordt alleen gebruikt om de configuratie te herkennen.',

        attribute_max_length: 'Attribute lengte',
        attribute_max_length_hint: 'Vul 0 in om de volledige lengte te gebruiken.',

        separate: 'Scheidingsteken',
        separate_hint: 'Vul niets in om geen scheidingsteken te gebruiken',

        fixed_attribute_length: 'Opvolgnummer lengte',
        fixed_attribute_length_hint: 'Vul 0 in om geen gefixeerde lengte te gebruiken',

        counter_alphanumeric: 'Gebruik alfanummmers',

        submit: 'Opslaan',

        // errors
        value_is_required: 'Dit veld is verplicht',
        name_too_long: 'Naam is te lang',
        attribute_too_long: 'Attribute is te lang',
        separator_too_long: 'Je kunt maar 1 scheidingsteken gebruiken',
      },
    },

    common: {
      confirm_button: {
        tooltip: 'Tik nogmaals om te bevestigen',
      },
    },
  },

  mixins: {
    form_handle: {
      bad_request: 'De aanvraag kan niet worden verwerkt vanwege een interne fout. Controleer of alle gegevens correct zijn ingevoerd en probeer het opnieuw.',
      unauthorized: 'Niet gemachtigd. Zorg ervoor dat u bent ingelogd en de juiste toestemmingen heeft om deze actie uit te voeren.',
      forbidden: 'Geen toegang. Neem contact op met de beheerder voor toestemming.',
      not_found: 'Niet gevonden. De opgevraagde gegevens kunnen niet worden gevonden.',
      server_error: 'Serverfout. Probeer het later opnieuw of neem contact op met de beheerder.',
    },

    enum: {
      address: {
        visit: 'Bezoekadres',
        postal: 'Postadres',
      },
      contact: {
        Administratie: 'Administratief',
        Financieel: 'Financiën',
        Transactiedata: 'Transactiegegevens',
        Verrijkingsdata: 'Verrijkingsgegevens',
        Commercie: 'Sales',
        'Api-koppeling': 'API',
        'EDI-koppeling': 'EDI',
        Hosting: 'Hosting',
      },
      country: {
        BE: 'België',
        DE: 'Duitsland',
        NL: 'Nederland',
      },
      gender: {
        male: 'Man',
        female: 'Vrouw',
      },
      availability: {
        '85E': 'Verkrijgbaar',
        '92E': 'Niet verkrijgbaar',
        '91E': 'Verkrijgbaar zolang de voorraad strekt',
        IBE: 'Onbekend',
      },
    },
  },

  views: {
    account: {
      account: {
        your_account_data: 'IB-account',
        personal_data: 'Persoonsgegevens wijzigen',
        change_password: 'Wachtwoord wijzigen',
        account_session: 'Sessie',
      },

      change_password: {
        change_password: 'Wachtwoord wijzigen',
        password_changed: 'Uw wachtwoord is gewijzigd.',

        // errors
        bad_request: 'Gegevens laden is mislukt: Request Verzoek fout.',
        unauthorized: 'U bent niet geauthenticeerd om gegevens te laden.',
        forbidden: 'U bent niet geautoriseerd om gegevens te laden.',
        not_found: 'Er zijn geen gegevens gevonden.',
        server_error: 'Gegevens laden is mislukt: Onbekende fout.',
      },

      login: {
        ib_thedatacompany_logo: 'IB the data company Logo',
        login_with_ib_account: 'Log in om eenvoudig zelf uw contactgegevens en voorkeuren te wijzigen.',

        // errors
        invalid_credentials: 'Het inloggen is mislukt: Ongeldige inloggegevens.',
        unknown_error: 'Het inloggen is mislukt: Serverfout.',
      },

      password_recovery: {
        ib_thedatacompany_logo: 'IB the data company Logo',
        request_password_recovery: 'Indien u uw wachtwoord bent vergeten kunt u via deze pagina een verzoek indienen voor het herstellen van uw wachtwoord. U krijgt dan per mail een link toegestuurd welke u eenmalig kunt gebruiken om een nieuw wachtwoord in te stellen',
        // success
        request_password_recovery_succeed: 'Het verzoek voor het herstellen van uw wachtwoord is aangevraagd.',
        redeem_recovery_secret_succeed: 'U ontvangt een e-mail met een link waarmee u uw wachtwoord kunt wijzigen. Deze link blijft 24 uur geldig.',
        // error
        secret_is_expired: 'De wachtwoord-reset tijd is verstreken. Vraag opnieuw een wachtwoordherstel aan',
        recovery_not_started: 'Deze verificatiecode is niet geldig.',
        unknown_error: 'Het herstellen van uw wachtwoord is mislukt: Onbekende fout.',
      },

      personal_data: {
        personal_information: 'Persoonsgegevens',

        firstname: 'Voornaam',
        your_firstname: 'Uw voornaam',
        lastname: 'Achternaam',
        your_lastname: 'Uw achternaam',

        firstname_too_long: 'Een voornaam kan niet meer dan 150 karakters bevatten',
        lastname_too_long: 'Een achternaam kan niet meer dan 150 karakters bevatten',
        value_is_required: 'Dit veld is verplicht',

        // errors
        bad_request: 'Gegevens laden is mislukt: Request fout.',
        unauthorized: 'U bent niet geauthenticeerd om gegevens te laden.',
        forbidden: 'U bent niet geautoriseerd om gegevens te laden.',
        not_found: 'Er zijn geen gegevens gevonden.',
        server_error: 'Gegevens laden is mislukt: Onbekende fout.',
      },

      session: {
        session: 'Sessie',
        session_created_on: 'Ingelogd op',
        session_expires_on: 'Ingelogd tot',
        identity: 'E-mailadres',
        first_name: 'Voornaam',
        last_name: 'Achternaam',
        function: 'Functie',
        company: 'Bedrijfsnaam',
      },
    },

    article: {
      articles: {
        article_db: 'Artikelen',

        search: 'Zoeken',
        ib_code: 'IB-Code',
        article_id: 'Artikelnummer',
        availability: 'Beschikbaarheid',
        description: 'Omschrijving',

        loading: 'Artikelen laden..',
        no_articles_loaded: 'Er zijn geen artikelen',
        no_articles_found: 'Geen artikelen gevonden',
        product_article_info: 'Product / Artikel uitgelegd',
        product_article_explained: 'TODO Schrijf een uitleg zodat de klant het verschil tussen product en artikel weet',
        actions: '',

        draft: 'Draft',
        revision_rollback: 'Alle wijzigingen ongedaan maken',
        revision_rollback_success: 'Alle wijzigingen zijn ongedaan gemaakt',
        revision_commit: 'Alle wijzigingen definitief maken',
        revision_commit_success: 'Alle wijzigingen zijn ingediend om definitief te maken',
      },

      article: {
        article: 'Artikel',
        articles: 'Artikelen',

        features: 'Eigenschappen',

        label: 'Eigenschap',
        value: 'Waarde',
        unit: 'Eenheid',
        group: 'Groep',
        actions: '',

        loading: 'Artikel eigenschappen laden..',
        no_features_loaded: 'Er zijn geen eigenschappen',
        no_features_found: 'Geen eigenschappen gevonden',

        product_article_info: 'Product / Artikel uitgelegd',
        product_article_explained: 'TODO Schrijf een uitleg zodat de klant het verschil tussen product en artikel weet',
        ib_code: 'IB-Code',
        article_id: 'Artikelnummer',
        availability: 'Beschikbaarheid',
        description: 'Omschrijving',

        fork_success: 'Artikel is gedupliceerd en kan gebruikt worden om te bewerken.',
        update_success: 'Artikel draft is bijgewerkt.',
        feature_update_success: 'Artikel eigenschap is bijgewerkt.',
      },

      products: {
        product_db: 'Producten',

        search: 'Zoeken',
        ib_code: 'IB-Code',
        description: 'Omschrijving',

        loading: 'Producten laden..',
        no_products_loaded: 'Er zijn geen producten',
        no_products_found: 'Geen producten gevonden',

        product_article_info: 'Product / Artikel uitgelegd',
        product_article_explained: 'TODO Schrijf een uitleg zodat de klant het verschil tussen product en artikel weet',
        actions: '',

        draft: 'Draft',
        revision_rollback: 'Alle wijzigingen ongedaan maken',
        revision_rollback_success: 'Alle wijzigingen zijn ongedaan gemaakt',
        revision_commit: 'Alle wijzigingen definitief maken',
        revision_commit_success: 'Alle wijzigingen zijn ingediend om definitief te maken',
      },

      product: {
        product: 'Product',
        products: 'Producten',

        features: 'Eigenschappen',

        label: 'Eigenschap',
        value: 'Waarde',
        unit: 'Eenheid',
        group: 'Groep',

        no_features_loaded: 'Er zijn geen eigenschappen',
        no_features_found: 'Geen eigenschappen gevonden',

        articles: 'Artikelen',
        article_id: 'Artikelnummer',
        availability: 'Beschikbaarheid',
        description: 'Omschrijving',

        search: 'Zoeken',
        ib_code: 'IB-Code',
        loading: 'Producten laden..',
        no_articles_loaded: 'Er zijn geen artikelen',
        no_articles_found: 'Geen artikelen gevonden',

        product_article_info: 'Product / Artikel uitgelegd',
        product_article_explained: 'TODO Schrijf een uitleg zodat de klant het verschil tussen product en artikel weet',

        actions: '',

        submit: 'Opslaan',

        fork_success: 'Product is gedupliceerd en kan gebruikt worden om te bewerken.',
        update_success: 'Product draft is bijgewerkt.',
        feature_update_success: 'Product eigenschap is bijgewerkt.',
      },
    },

    company: {
      addresses: {
        addresses: 'Vestigingen',
        no_addresses_found: 'Er zijn nog geen vestigingen van uw bedrijf bij ons bekend.',
      },

      company_info: {
        company_overview: 'Bedrijfsgegevens',

        enter_company_name: 'Bedrijfsnaam opgeven',
        enter_public_known_company_name: 'De handelsnaam van uw onderneming zoals deze bij de KvK geregistreerd staat.',
        company_name: 'Bedrijfsnaam',
        company_name_length_error: 'Bedrijfsnaam kan niet meer dan 200 karakters bevatten',

        enter_coc_number: 'KVK-nummer opgeven',
        coc_number: 'KVK-nummer',
        coc_number_length_error: 'Een valide KVK-nummer bestaat uit 8 cijfers',

        location: 'Locatie',

        enter_gln: 'GLN opgeven',
        global_location_number: 'GLN staat voor Global Location Number. Het is een nummer van '
          + '8, 12, 13 of 14 posities lang (afhankelijk van het land), waarmee een fysieke '
          + 'locatie van bijvoorbeeld een kantoor, winkel of magazijn als uniek adres '
          + 'geïdentificeerd wordt. Een GLN maakt wereldwijd unieke en ondubbelzinnige '
          + 'identificatie van fysiek of operationele locaties mogelijk. In Duitsland wordt een '
          + 'GLN ook wel ILN genoemd.',
        gln: 'GLN',
        gln_length_error: 'Uw GLN kan niet meer dan 60 karakters bevatten',

        contact: 'Contact',

        enter_email: 'E-mailadres opgeven',
        email: 'E-mailadres',
        email_invalid_error: 'Vul een geldig e-mailadres in',
        email_length_error: 'Een e-mailadres kan niet meer dan 128 karakters bevatten',

        enter_phone_nr: 'Telefoonnummer opgeven',
        phone_nr: 'Telefoonnummer',
        phone_nr_length_error: 'Een telefoonnummer kan niet meer dan 200 karakters bevatten',

        enter_fax_nr: 'Faxnummer opgeven',
        fax_nr: 'Faxnummer',
        fax_nr_length_error: 'Een faxnummer kan niet meer dan 200 karakters bevatten',

        enter_website: 'Website opgeven',
        website: 'Website',
        website_length_error: 'Uw website-url kan niet meer dan 1024 karakters bevatten',

        upload_company_logo: 'Bedrijfslogo',
        opening_hours: 'Openingstijden',
        contact_persons: 'Medewerkers',
        company_addresses: 'Adressen',

        value_is_required: 'Dit veld is verplicht',
      },

      logo_upload: {
        email: 'E-mailadres',
        website: 'Website',
        gln: 'GLN',
        allowed_file_types: 'Upload alleen .PNG, .JPG en .JPEG-afbeeldingen',
      },

      contact_person: {
        employees: 'Medewerkers',
        no_employees_found: 'Er zijn nog geen medewerkers voor deze organisatie bij ons bekend.',
      },

      opening_hours: {
        opening_hours: 'Openingstijden',

        mo: 'Maandag',
        tu: 'Dinsdag',
        we: 'Woensdag',
        th: 'Donderdag',
        fr: 'Vrijdag',
        sa: 'Zaterdag',
        su: 'Zondag',

        open: 'Open',
        closed: 'Gesloten',
      },
    },

    error: {
      title: '404',
      sub_title: 'De pagina kon niet worden gevonden.',
      text: 'Controleer of u het juiste adres heeft gebruikt. Mocht u uw bestemming niet weten te vinden, neem dan contact op',
      return_link_text: 'of keer terug naar de beginpagina',
    },

    main: {
      dashboard: {
        welcome_title: 'Hallo ',
        welcome_subtitle: 'Welkom op het IB portaal.',
      },

      settings: {
        // used in features. Move to the list below when the feature is permanent.
        settings_page: 'Instellingen pagina',
        //
        app_settings: 'App instellingen',
        dark_mode: 'Dark mode',
        language: 'Taal',
        experimental_features: 'Experimentele functies',
        features_warning: 'Deze functies zijn experimenteel! Er kunnen fouten optreden als u een van deze functies inschakelt.',
        apis: 'API\'s',
        login_api: 'Login API',
        company_manager: 'Company Manager API',
        product_article_manager: 'Product Article Manager API',
        // feature flags
        SETTINGS_VIEW: 'App instellingen',
      },
    },

    sku: {
      config: {
        sku_config: 'SKU generator config',
        sku_config_info: 'SKU generator uitgelegd',

        no_name: 'Geen naam',
        attribute_length: 'Max. attribute lengte: ',
        separate: 'Scheidingsteken: ',
        counter_length: 'Gefixeerde nummer lengte: ',
        counter_alphanumeric: 'Gebruik alfanummers: ',

        sku_config_explained: 'Nog te doen',
      },

      upload_prefixes: {
        sku_prefixes: 'SKU prefixes',
        sku_prefixes_info: 'SKU prefixes uitgelegd',
        sku_prefixes_explained: 'Nog te doen',
      },

      numbers: {
        sku_numbers: 'Sku nummers',
        sku_numbers_info: 'Sku nummers uitgelegd',
        sku_numbers_explained: 'Nog te doen',

        generate: 'Genereren',
        delete: 'Configuratie verwijderen',
      },
    },
  },
};
