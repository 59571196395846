<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :disabled="loading"
    lazy-validation
    @submit.prevent="submit"
  >
    <v-card flat>

      <v-card-title>{{ _('login_as') }}</v-card-title>

      <v-card-text>
        <v-text-field
          v-model="dto.email"
          :errorMessages="errorBag.email"
          :hint="_('use_your_ib_credentials')"
          :label="_('username')"
          :rules="[defaultRules.required, defaultRules.email]"
          autocomplete="username"
          autofocus
          name="email"
          outlined
          required
          type="email"
          validate-on-blur
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          color="primary"
          type="submit"
        >{{ _('login') }}</v-btn>
      </v-card-actions>

    </v-card>
  </v-form>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import translateMixin from '@/mixins/translate';

export default {
  name: 'AppBarSessionImpersonateForm',

  mixins: [formMixin, translateMixin],

  data() {
    return {
      // @see translateMixin.translateKeyPrefix
      translateKeyPrefix: 'components.layout.impersonate_form',
    };
  },
};
</script>
