import ClientError from '@/services/http/errors/ClientError';

/**
 * A HTTP 401 Unauthorized error.
 */
export default class UnauthorizedError extends ClientError {
  /**
   * @param {Response|string|undefined} response
   */
  constructor(response) {
    super(response);

    if ((response instanceof Response) === false) {
      this.statusCode = 401;
      this.message = 'Unauthorized';
    }
  }
}
