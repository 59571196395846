<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :disabled="loading"
    lazy-validation
    @submit.prevent="submit"
  >
    <v-card flat>

      <v-card-title>{{ _('request_password_recovery') }}</v-card-title>

      <v-card-text>
        <p>{{ _('enter_the_username_known_by_ib') }}</p>

        <v-text-field
          id="username"
          v-model="dto.email"
          :errorMessages="errorBag.email"
          :label="_('enter_username')"
          :rules="emailValidationRules"
          :tabindex="0"
          autocomplete="username"
          autofocus
          name="username"
          outlined
          required
          type="email"
          validate-on-blur
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :to="{ name: 'Login' }"
          small
          text
        >{{ _('login') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          :tabindex="2"
          color="primary"
          type="submit"
        >{{ _('start_recovery') }}</v-btn>
      </v-card-actions>

    </v-card>
  </v-form>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import translateMixin from '@/mixins/translate';

/**
 * Password reset form.
 *
 * This form can be used to enter an email from the account for which the password needs to be reset
 */
export default {
  name: 'PasswordRecoveryForm',

  mixins: [formMixin, translateMixin],

  data() {
    return {
      // @see translateMixin.translateKeyPrefix
      translateKeyPrefix: 'components.account.password_recovery_form',

      emailValidationRules: [
        (v) => !!v || this._('username_is_required'),
        (v) => /.+@.+/.test(v) || this._('username_is_invalid'),
      ],
    };
  },
};
</script>
