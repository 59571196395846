import AbstractModel from '@/model/AbstractModel';
import Company from '@/model/Company';

export default class Article extends AbstractModel {
  static entity = 'articles';

  static primaryKey = 'ibCode';

  static updateMethod = AbstractModel.PATCH;

  static fields() {
    return {
      ibCode: this.number(null).nullable(),
      articleId: this.string(null).nullable(),
      productIbCode: this.string(null).nullable(),
      description: this.string(null).nullable(),
      availability: this.string(null).nullable(),
      revisionState: this.string(null).nullable(),
      extendedInfo: this.string(null).nullable(),
      publishDate: this.attr('').nullable(),
      created: this.attr('').nullable(),
      modified: this.attr('').nullable(),
      companyId: this.string(null).nullable(),
      manufacturer: this.belongsTo(Company, 'companyId', 'uuid'),
    };
  }
}
