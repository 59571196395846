<template>
  <v-row justify="center" no-gutters>
    <v-col lg="6" md="8" sm="12" xl="4">
      <v-row>
        <v-col>
          <v-card outlined>

            <v-progress-linear
              :active="isLoading"
              absolute
              color="primary"
              indeterminate
              top
            ></v-progress-linear>

            <v-toolbar flat dense>
              <v-btn
                :to="{ name: 'Account' }"
                data-test="navBackBtn"
                exact-path
                icon
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-toolbar>

            <v-img
              :src="require('@/assets/undraw/undraw_unlock_24mb.svg')"
              alt=""
              class="shrink my-5"
              contain
              height="152"
              transition="scale-transition"
            />

            <v-card-title>
              <span>{{ _('change_password') }}</span>
              <v-divider inset></v-divider>
            </v-card-title>

            <ChangePasswordForm
              ref="changePasswordForm"
              v-model="dto"
              :errorBag="errorBag"
              :loading="isLoading"
              @submit="handleChangePassword"
            ></ChangePasswordForm>

          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ChangePasswordForm from '@/components/account/ChangePasswordForm.vue';
import formHandleMixin from '@/mixins/formHandle';
import messageMixin from '@/mixins/message';
import redirectMixin from '@/mixins/redirect';
import translateMixin from '@/mixins/translate';
import BadRequestError from '@/services/http/errors/BadRequestError';
import ForbiddenError from '@/services/http/errors/ForbiddenError';
import NotFoundError from '@/services/http/errors/NotFoundError';
import UnauthorizedError from '@/services/http/errors/UnauthorizedError';
import UnprocessableEntityError from '@/services/http/errors/UnprocessableEntityError';

/**
 * Password change view.
 */
export default {
  name: 'ChangePasswordView',

  components: { ChangePasswordForm },

  mixins: [
    formHandleMixin,
    messageMixin,
    redirectMixin,
    translateMixin,
  ],

  data() {
    return {
      // @see translateMixin.translateKeyPrefix
      translateKeyPrefix: 'views.account.change_password',
    };
  },

  methods: {
    /**
     * Handles the password change submission.
     *
     * @return {Promise<void>}
     */
    async handleChangePassword() {
      this.isLoading = true;

      try {
        // update the password
        await this.$store.dispatch('account/patchPassword', this.dto);

        // display a success message and redirect
        this.$store.commit('messages/add', this._('password_changed'));
        await this.$router.push({ name: 'Account' });
      } catch (error) {
        if (error instanceof UnprocessableEntityError) {
          this.errorBag = { ...this.errorBag, ...error.getViolations() };
        } else if (error instanceof BadRequestError) {
          this.displayMessage(this._('bad_request'));
        } else if (error instanceof UnauthorizedError) {
          this.displayMessage(this._('unauthorized'));
        } else if (error instanceof ForbiddenError) {
          this.displayMessage(this._('forbidden'));
        } else if (error instanceof NotFoundError) {
          this.displayMessage(this._('not_found'));
        } else {
          this.displayMessage(this._('server_error'));
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
