import databaseModuleFactory from '@/store/databaseModuleFactory';

/**
 * Factory function to create a VuexORM module for the company module.
 *
 * This factory create a normal databaseResource with an additional method to upload company logo's.
 *
 * @see https://vuex-orm.org/guide/digging-deeper/vuex-module.html#defining-modules
 *
 * @param entity
 * @param api
 * @return {{
 *   mutations: {
 *     UPDATE_TOTAL_ITEMS(*, *): void
 *   },
 *   state: {
 *     totalItems: undefined
 *   },
 *   getters: {
 *     findBy: (function(*, *): function(*): *)
 *   },
 *   actions: {
 *     patch({dispatch: *}, {parameters?: {any}, payload?: {any}}): Promise<Array<*>>,
 *     load({dispatch: *}, {parameters?: {any}}): Promise<Array<*>>,
 *     loadBy({dispatch: *, commit: *}, {parameters?: {any}}): Promise<Array<*>>,
 *     persist({dispatch: *}, {parameters?: {any}, payload?: {any}}): Promise<Array<*>>,
 *     put({dispatch: *}, {parameters?: {any}, payload?: {any}}): Promise<Array<*>>,
 *     remove({dispatch: *}, {parameters?: {any}}): Promise<void>,
 *     uploadLogo({dispatch: *}, {}): Promise<void>,
 *   },
 *   namespaced: boolean
 * }}
 */
export default (entity, api) => {
  const databaseResource = databaseModuleFactory(entity, api);

  return {
    ...databaseResource,

    actions: {
      ...databaseResource.actions,

      /**
       * Upload a company logo image.
       *
       * @param dispatch
       * @param {string|number} id
       * @param {File} file
       * @return {Promise<*>}
       */
      async uploadLogo({ dispatch }, { id, file }) {
        const payload = new FormData();
        payload.append('logo', file);

        const { data } = await api.uploadOne({ id, payload });

        return dispatch(
          'database/insert',
          { entity, data },
          { root: true },
        );
      },
    },
  };
};
