export default {
  methods: {
    /**
     * Navigate to the path found in the current route query.
     *
     * @param {string} fallbackRouteName Navigated to when no redirect is found in the query.
     * @return {void}
     */
    redirectAfterLogin(fallbackRouteName = 'Dashboard') {
      let location = this.$route.query?.redirect;
      if (typeof location !== 'string') {
        location = { name: fallbackRouteName };
      }

      this.$router
        .push(location)
        .catch((e) => e); // ignore the error
    },

    /**
     * Navigate to the given route name with the current route path as redirect query.
     *
     * @param {string} toRouteName
     * @return {void}
     */
    redirectAfterLogout(toRouteName = 'Login') {
      this.$router.push({ name: toRouteName, query: { redirect: this.$route.fullPath } });
    },
  },
};
