import account from '@/store/module/account';
import openApi from '@/store/module/openApi';
import messages from '@/store/module/messages';
import session from '@/store/module/session';
import settings from '@/store/module/settings';

export default {
  account,
  messages,
  openApi,
  session,
  settings,
};
