/**
 * App settings helper.
 *
 * <NewComponent v-if="isFeatureEnabled('NEW_COMPONENT')">
 * <OldComponent v-else>
 *
 * @see the settings store module to change the feature flags.
 */
export default {
  computed: {
    /**
     * Returns all feature flags.
     *
     * @type {{string: boolean}}
     */
    featureFlags() {
      return this.$store.getters['settings/featureFlags'];
    },

    /**
     * Returns the value of a feature flag.
     *
     * @type {function(string): boolean}
     */
    isFeatureEnabled() {
      return (name) => !!this.featureFlags[name] ?? false;
    },

    /**
     * Returns all user preferences
     *
     * @type {{string: any}}
     */
    preferences() {
      return this.$store.getters['settings/preferences'];
    },

    /**
     * Returns the value of a preference.
     *
     * @return {function(string): any}
     */
    preference() {
      return (name) => this.preferences[name];
    },
  },
};
