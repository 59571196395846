<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :disabled="loading"
    lazy-validation
    @submit.prevent="submit"
  >
    <v-card flat>

      <v-card-text>

        <PasswordField
          v-model="dto.password"
          :errorMessages="errorBag.password"
          :label="_('new_password')"
          :newPassword="true"
          :rules="passwordValidationRules"
          :tabindex="0"
          name="password"
          outlined
          required
        ></PasswordField>

        <PasswordField
          v-model="repeatPassword"
          :errorMessages="errorBag.repeatPassword"
          :label="_('repeat_password')"
          :newPassword="false"
          :rules="passwordRepeatValidationRules"
          :tabindex="0"
          name="repeat-password"
          outlined
          required
        ></PasswordField>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          :tabindex="2"
          color="primary"
          type="submit"
        >{{ _('change_password') }}</v-btn>
      </v-card-actions>

    </v-card>
  </v-form>
</template>

<script>
import PasswordField from '@/components/common/PasswordField.vue';
import formMixin from '@/mixins/formMixin';
import translateMixin from '@/mixins/translate';

/**
 * Password change form.
 *
 * Use this form to change the password of the currently authenticated user.
 */
export default {
  name: 'ChangePasswordForm',

  components: { PasswordField },

  mixins: [formMixin, translateMixin],

  data() {
    return {
      // @see translateMixin.translateKeyPrefix
      translateKeyPrefix: 'components.account.change_password_form',

      passwordValidationRules: [
        (v) => !!v || this._('password_is_required'),
      ],

      passwordRepeatValidationRules: [
        (v) => !!v || this._('password_is_required'),
        (v) => (v === this.dto.password) || this._('passwords_need_to_be_equal'),
      ],

      /**
       * Placeholder for the re-entered password
       */
      repeatPassword: '',
    };
  },
};
</script>
