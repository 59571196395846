import AbstractModel from '@/model/AbstractModel';

export default class Contact extends AbstractModel {
  static entity = 'contact';

  static primaryKey = 'id';

  static updateMethod = AbstractModel.PUT;

  static fields() {
    return {
      id: this.number(null).nullable(),
      company_id: this.number(null),

      gender: this.string(null).nullable(), // NULL, m, w
      firstName: this.string(null).nullable(),
      lastName: this.string(null).nullable(),
      mobileNr: this.string(null).nullable(),
      phoneNr: this.string(null).nullable(),
      email: this.string(null).nullable(),
      primaryContactFor: this.attr([]),
      function: this.string(null).nullable(),
      availability: this.string(null).nullable(),
    };
  }

  get dto() {
    const dto = this.$toJson();
    delete dto.company_id;

    return dto;
  }

  set dto(data) {
    this.$fill({ ...data, id: data.id });
  }
}
