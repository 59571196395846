/**
 * Translation helper.
 *
 * This mixin can be used to translate text used in components.
 */
export default {
  data() {
    return {
      /**
       * The patch in the translation object.
       *
       * A translation for the settings view would look something like
       * `_('view.main.settings.title')`.This is a long key, if no translation is found it will
       * display the key, polluting the component. A key like `_('title')` is much better, for this
       * you need to override the `translateKeyPrefix: 'view.main.settings'`. Doing this, enables a
       * feature. You can use keys as fallback. So, instead of the key `title` you can use
       * `app_settings` which is almost equal to its english translation, `App settings`.
       *
       * @see @/locale/*.js
       * @abstract
       */
      translateKeyPrefix: '',
    };
  },

  methods: {
    /**
     * @param {string} key
     * @param {string} prefix
     * @return {string}
     */
    translate(key, prefix = '$vuetify.') {
      return this.$vuetify.lang.t(`${prefix}${key}`);
    },

    /**
     * Translate the given key.
     *
     * @param {string} key
     * @return {string}
     */
    _(key) {
      const prefix = this.translateKeyPrefix.length > 0 ? `${this.translateKeyPrefix}.` : '';
      let translationKey = `$vuetify.${prefix}${key}`;
      // WARNING this causes warnings: When a translation key is not found.
      // a warning will be logged. Find a way to not log warnings until the translation key isn't
      // found.
      let translation = this.translate(translationKey, '');

      if (translation === translationKey) {
        translationKey = key;
        translation = this.translate(translationKey, '');
      }

      if (translation === translationKey) {
        translation = key;
      }

      return translation;
    },
  },
};
