export default {
  computed: {
    /**
     * @return {User}
     */
    currentUser() {
      const userId = this.$store.getters['session/userId'];
      return this.$store.getters['database/user/find'](userId) || {};
    },

    /**
     * @return {string}
     */
    userInitials() {
      const { firstName, lastName } = this.currentUser;
      if (
        firstName === undefined
        || firstName === null
        || lastName === undefined
        || lastName === null
      ) {
        return '';
      }

      return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
    },

    /**
     * @return {string}
     */
    fullName() {
      const { firstName, lastName } = this.currentUser;

      return `${firstName} ${lastName}`;
    },

    /**
     * @return {string}
     */
    firstName() {
      const { firstName } = this.currentUser;

      return firstName;
    },

    /**
     * @return {string}
     */
    email() {
      const { email } = this.currentUser;

      return email;
    },
  },
};
