<template>
  <v-row justify="center" no-gutters>
    <v-col lg="6" md="8" sm="12" xl="4">
      <v-row>
        <v-col>
          <v-card outlined>

            <v-progress-linear
              :active="isLoading"
              absolute
              color="primary"
              indeterminate
              top
            ></v-progress-linear>

            <v-toolbar dense flat>
              <v-btn
                :to="{ name: 'Account' }"
                data-test="navBackBtn"
                exact-path
                icon
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-toolbar>

            <v-img
              :src="require('@/assets/undraw/undraw_personal_info_-0-okl.svg')"
              alt=""
              class="shrink my-5"
              contain
              height="152"
              transition="scale-transition"
            />

            <v-card-title>
              <span>{{ _('personal_information') }}</span>
              <v-divider inset></v-divider>
            </v-card-title>

            <v-list>

              <EditableListItem
                :dataModel="dto"
                :errorBag="errorBag"
                :placeholder="_('your_firstname')"
                :title="_('firstname')"
                :validation="[rules.required, rules.firstNameLength]"
                itemIcon="mdi-account-circle"
                property="firstName"
                @submit="saveForm"
              ></EditableListItem>

              <EditableListItem
                :dataModel="dto"
                :errorBag="errorBag"
                :placeholder="_('your_lastname')"
                :title="_('lastname')"
                :validation="[rules.required, rules.lastNameLength]"
                itemIcon="mdi-account-circle"
                property="lastName"
                @submit="saveForm"
              ></EditableListItem>

            </v-list>

          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import EditableListItem from '@/components/common/EditableListItem.vue';
import formHandleMixin from '@/mixins/formHandle';
import messageMixin from '@/mixins/message';
import translateMixin from '@/mixins/translate';
import User from '@/model/User';

export default {
  name: 'PersonalDataView',

  components: { EditableListItem },

  mixins: [
    formHandleMixin,
    messageMixin,
    translateMixin,
  ],

  mounted() {
    this.loadForm(this.$store.getters['session/userId']);
  },

  data() {
    return {
      // @see translateMixin.translateKeyPrefix
      translateKeyPrefix: 'views.account.personal_data',

      // @see formHandleMixin and tableMixin
      modelClass: User,

      rules: {
        required: (v) => !!v || 'Dit veld is verplicht',
        firstNameLength: (v) => (v !== null && v.length <= 150) || this._('firstname_too_long'),
        lastNameLength: (v) => (v !== null && v.length <= 150) || this._('lastname_too_long'),
      },
    };
  },
};
</script>
