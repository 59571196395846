/**
 * A HTTP 5xx server error.
 */
export default class ServerError extends Error {
  /**
   * The Response object causing this error, or a error message.
   *
   * @type {Response|string|undefined}
   */
  response;

  /**
   * @type {number} The response status code.
   */
  statusCode;

  /**
   * @param {Response|string|undefined} response
   */
  constructor(response) {
    let statusCode = 500;
    let statusText = 'Internal Server Error';

    if (response instanceof Response) {
      statusCode = response.status;
      statusText = response.statusText;
    }

    if (typeof response === 'string') {
      statusText = response;
    }

    super(statusText);

    this.response = response;
    this.statusCode = statusCode;
  }
}
