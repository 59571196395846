import AbstractModel from '@/model/AbstractModel';

export default class ProductFeature extends AbstractModel {
  static entity = 'product_features';

  static primaryKey = 'id';

  static updateMethod = AbstractModel.PATCH;

  static fields() {
    return {
      id: this.number(null).nullable(),
      ibCode: this.string(null).nullable(),
      value: this.string(null).nullable(),
      unit: this.string(null).nullable(),
      name: this.string(null).nullable(),
      label: this.string(null).nullable(),
      weight: this.string(null).nullable(),
      groupName: this.attr('').nullable(),
      groupWeight: this.attr('').nullable(),
    };
  }
}
