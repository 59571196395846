import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';
import guardFactory from '@/router/guard';
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// set up a guard to watch all routes
guardFactory(router, store);

export default router;
