import parseJwt from '@/services/jwt/parse';

/**
 * Local JWT store.
 *
 * This JWT store can be used to store a JWT in local storage.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Storage
 */

/**
 * The local storage key, used in the `*Item` methods.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Storage#methods
 *
 * @type {string}
 */
const storageName = 'ib.auth.token';

/**
 * @return {string|null}
 */
function getToken() {
  const token = localStorage.getItem(storageName);

  // check the token expiration date
  if (typeof token === 'string') {
    const { payload } = parseJwt(token);
    const expirationDate = new Date(payload.exp * 1000);
    const now = Date.now();
    if (now > expirationDate) {
      // token is expired
      return null;
    }
  }

  return token;
}

/**
 * @param {string} token
 * @return {void}
 */
function setToken(token) {
  if (typeof token === 'string') {
    localStorage.setItem(storageName, token);
  }
}

/**
 * @return {boolean}
 */
function hasToken() {
  return (getToken() !== null);
}

/**
 * @return {void}
 */
function removeToken() {
  localStorage.removeItem(storageName);
}

/**
 * A dedicated localStorage to store the authentication token.
 */
export {
  getToken,
  hasToken,
  removeToken,
  setToken,
};
