<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :disabled="loading"
    lazy-validation
    @submit.prevent="submit"
  >
    <v-card flat>

      <v-card-title>{{ _('redeem_recovery_secret') }}</v-card-title>

      <v-card-text>
        <p>{{ _('enter_received_secret') }}</p>

        <v-text-field
          id="recoverySecret"
          v-model="dto.secret"
          :errorMessages="errorBag.secret"
          :label="_('enter_secret')"
          :rules="secretValidationRules"
          :tabindex="0"
          autofocus
          name="secret"
          outlined
          required
          validate-on-blur
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-btn
          small
          text
          @click="reset"
        >{{ _('restart_recovery') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          :tabindex="2"
          color="primary"
          type="submit"
        >{{ _('redeem_secret') }}</v-btn>
      </v-card-actions>

    </v-card>
  </v-form>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import translateMixin from '@/mixins/translate';

/**
 * Recovery secret redeem form.
 *
 * This form can be used to enter a recovery secret.
 */
export default {
  name: 'RecoverySecretRedeemForm',

  mixins: [formMixin, translateMixin],

  data() {
    return {
      // @see translateMixin.translateKeyPrefix
      translateKeyPrefix: 'components.account.recovery_secret_redeem_form',

      secretValidationRules: [
        (v) => !!v || this._('secret_is_required'),
        (v) => (!v || v.length === 32) || this._('secret_is_invalid'),
      ],
    };
  },
};
</script>
