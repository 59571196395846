<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :disabled="loading"
    lazy-validation
    @submit.prevent="submit"
  >
    <v-card flat>

      <v-card-title>{{ _('login_with_username_and_password') }}</v-card-title>

      <v-card-text>

        <v-text-field
          id="username"
          v-model="dto.email"
          :hint="_('use_the_username_known_by_ib')"
          :label="_('enter_username')"
          :rules="emailValidationRules"
          :tabindex="0"
          autocomplete="username"
          autofocus
          name="username"
          outlined
          required
          type="email"
          validate-on-blur
        ></v-text-field>

        <PasswordField
          v-model="dto.password"
          :errorMessages="errorBag.password"
          :label="_('enter_password')"
          :newPassword="false"
          :rules="passwordValidationRules"
          :tabindex="0"
          name="password"
          outlined
          required
        ></PasswordField>

      </v-card-text>

      <v-card-actions>
        <v-btn
          :to="{ name: 'PasswordRecovery' }"
          small
          text
        >{{ _('forgot_my_password') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          :tabindex="2"
          color="primary"
          type="submit"
        >{{ _('login') }}</v-btn>
      </v-card-actions>

    </v-card>
  </v-form>
</template>

<script>
import PasswordField from '@/components/common/PasswordField.vue';
import formMixin from '@/mixins/formMixin';
import translateMixin from '@/mixins/translate';

/**
 * Login form
 *
 * This is the main login form that users should see when not logged in.
 */
export default {
  name: 'LoginForm',

  components: { PasswordField },

  mixins: [formMixin, translateMixin],

  data() {
    return {
      // @see translateMixin.translateKeyPrefix
      translateKeyPrefix: 'components.account.login_form',

      emailValidationRules: [
        (v) => !!v || this._('username_is_required'),
        (v) => /.+@.+/.test(v) || this._('username_is_invalid'),
      ],

      passwordValidationRules: [
        (v) => !!v || this._('password_is_required'),
      ],
    };
  },
};
</script>
