import Vue from 'vue';
import Vuetify from '@/plugins/vuetify';

const defaults = {
  languages: process.env.VUE_APP_LANG_LIST.split(','),
  feature_flags: {
    SETTINGS_VIEW: process.env.VUE_APP_FEATURE_FLAG_SETTINGS_VIEW === 'true',
  },
  preferences: {
    dark_mode: process.env.VUE_APP_PREF_DARK_MODE === 'true',
    lang: process.env.VUE_APP_PREF_LANG,
  },
  endpoints: {
    company_manager_logo: process.env.VUE_APP_COMPANY_LOGO_UPLOAD_ENDPOINT,
  },
  apis: {
    login_api: process.env.VUE_APP_API_LOGIN,
    company_manager: process.env.VUE_APP_API_COMPANY,
    product_article_manager: process.env.VUE_APP_API_PRODUCT_ARTICLE,
  },
};

/**
 * Settings store module.
 *
 * This module is used to store User preferences and feature flags.
 *
 * @see the featureFlags mixin
 */
export default {
  namespaced: true,

  state: () => defaults,

  mutations: {
    /**
     * @param state
     * @param {{string: any}} payload
     */
    UPDATE_PREFERENCES(state, payload) {
      Vue.set(state, 'preferences', { ...state.preferences, ...payload });
    },

    /**
     * @param state
     * @param {{string: boolean}} payload
     */
    UPDATE_FEATURE_FLAGS(state, payload) {
      Vue.set(state, 'feature_flags', { ...state.featureFlags, ...payload });
    },

    /**
     * @param state
     * @param {{string: string}} payload
     */
    UPDATE_APIS(state, payload) {
      Vue.set(state, 'apis', { ...state.apis, ...payload });
    },

    /**
     * @param state
     */
    RESET(state) {
      Object.assign(state, defaults);
    },
  },

  getters: {
    /**
     * @param state
     * @return {{string: string}}
     */
    apis: (state) => state.apis || defaults.apis,

    /**
     * @param state
     * @return {{string: boolean}}
     */
    featureFlags: (state) => state.feature_flags || defaults.feature_flags,

    /**
     * @param state
     * @return {function(string): boolean}
     */
    isFeatureEnabled: (state) => (feature) => !!state.feature_flags[feature],

    /**
     * @param state
     * @return {{string: any}}
     */
    preferences: (state) => state.preferences || defaults.preferences,
  },

  actions: {
    /**
     * Apply the current settings.
     *
     * This action should be called whenever settings are changed that are not reactive.
     *
     * @param getters
     * @return {void}
     */
    apply({ getters }) {
      // apply App dark mode preference
      Vuetify.framework.theme.dark = getters.preferences.dark_mode;
      // apply App language preference
      Vuetify.framework.lang.current = getters.preferences.lang;
    },

    /**
     * Reset the current settings to the initial state.
     *
     * This action should be called when the user logs-out.
     *
     * @param commit
     * @param dispatch
     * @return {void}
     */
    reset({ commit, dispatch }) {
      commit('RESET');
      dispatch('apply');
    },
  },
};
