import AbstractModel from '@/model/AbstractModel';

export default class User extends AbstractModel {
  static entity = 'user';

  static primaryKey = 'id';

  static updateMethod = AbstractModel.PATCH;

  static fields() {
    return {
      id: this.string(null).nullable(),
      email: this.string(null).nullable(),
      password: this.string(null).nullable(),
      firstName: this.string(null).nullable(),
      lastName: this.string(null).nullable(),
      function: this.string(null).nullable(),
      companyId: this.string(null).nullable(),
      companyName: this.string(null).nullable(),
      roles: this.attr([]),
    };
  }
}
