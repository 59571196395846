<template>
  <v-app>
    <AppBar></AppBar>

    <v-main>
      <v-container>
        <router-view/>
      </v-container>
    </v-main>

    <AppFooter></AppFooter>
  </v-app>
</template>

<script>
import AppBar from '@/components/layout/AppBar.vue';
import AppFooter from '@/components/layout/AppFooter.vue';

/**
 * Simple content layout with only App-bar and footer.
 */
export default {
  name: 'ContentLayout',

  components: {
    AppFooter,
    AppBar,
  },
};
</script>
