/**
 * The router guard will redirect the user to the login view if the requested view requires
 * authentication while the user isn't authenticated.
 *
 * @see https://router.vuejs.org/guide/advanced/navigation-guards.html
 * @param {VueRouter} router
 * @param {Store} store
 */
export default function factory(router, store) {
  router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters['session/hasSession'];
    const expirationTime = store.getters['session/expirationTime'];

    if (
      to.meta?.requiresAuth !== false
      && (isAuthenticated === false || expirationTime <= 0)
      && to.name !== 'Login'
    ) {
      const query = { redirect: to.fullPath };
      if (expirationTime <= 0) {
        query.messageCode = 3;
      }

      // The requested view requires an authenticated user, redirect to the login view, and redirect
      // back to the origin after the user is authenticated
      next({ name: 'Login', query });
      return;
    }

    // do nothing, continue the route
    next();
  });
}
