import ClientError from '@/services/http/errors/ClientError';

/**
 * A HTTP 403 Forbidden error.
 */
export default class ForbiddenError extends ClientError {
  /**
   * @param {Response|string|undefined} response
   */
  constructor(response) {
    super(response);

    if ((response instanceof Response) === false) {
      this.statusCode = 403;
      this.message = 'Forbidden';
    }
  }
}
