<template>
  <v-row justify="center">
    <v-col>
      <v-row justify="center">
        <v-img
          :alt="_('ib_thedatacompany_logo')"
          :src="require('@/assets/logo/logo.png')"
          class="shrink ma-10"
          transition="scale-transition"
          width="75"
        />
        <span class="text-caption grey--text">{{ appVersion }}</span>
      </v-row>

      <v-row justify="center">
        <p class="caption blue-grey--text--text text-center ma-3"
        >{{ _('login_with_ib_account') }}</p>
      </v-row>

      <v-row justify="center">
        <v-card outlined width="450">
          <LoginForm
            v-model="dto"
            :errorBag="errorBag"
            :loading="isLoading"
            @submit="handleLogin"
          ></LoginForm>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import LoginForm from '@/components/account/LoginForm.vue';
import formHandleMixin from '@/mixins/formHandle';
import messageMixin from '@/mixins/message';
import redirectMixin from '@/mixins/redirect';
import translateMixin from '@/mixins/translate';
import UnauthorizedError from '@/services/http/errors/UnauthorizedError';

/**
 * The login view.
 *
 * This view display a login form, dispatched the login credentials and handles error's if any.
 */
export default {
  name: 'LoginView',

  mixins: [
    formHandleMixin,
    messageMixin,
    redirectMixin,
    translateMixin,
  ],

  components: { LoginForm },

  data: () => ({
    // @see translateMixin.translateKeyPrefix
    translateKeyPrefix: 'views.account.login',
    // the app version
    appVersion: process.env.VUE_APP_VERSION,
  }),

  methods: {
    /**
     * Try to authenticate the user with the entered credentials.
     *
     * @return {Promise<void>}
     */
    async handleLogin() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('session/authenticateWithUsernameAndPassword', this.dto);
        this.redirectAfterLogin();
      } catch (error) {
        if (error instanceof UnauthorizedError) {
          // invalid credential's error, display an error message below the password field
          this.errorBag.password = [this._('invalid_credentials')];
        } else {
          this.displayMessage(this._('unknown_error'));
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
