import Vue from 'vue';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import App from './App.vue';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;

/**
 * Mixin with global available helpers.
 */
Vue.mixin({
  methods: {
    /**
     * Clone an Object without Vue observers.
     *
     * @param {Object} obj
     * @return {Object}
     */
    deepClone: (obj) => JSON.parse(JSON.stringify(obj)),
  },
});

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

if (window.Cypress !== undefined) {
  // expose the vue.js app to the window when under test
  window.vue = app;
}
