<template>
  <v-text-field
    :id="autocomplete"
    v-model="plainPassword"
    :append-icon="showPasswordIcon"
    :autocomplete="autocomplete"
    :errorMessages="errorMessages"
    :hint="passwordSuggestion"
    :label="label"
    :loading="isStrengthIndicatorShown"
    :name="name"
    :outlined="outlined"
    :required="required"
    :rules="rules"
    :tabindex="tabindex"
    :type="fieldType"
    validate-on-blur
    @input="$emit('input', plainPassword)"
    @click:append="showPlainPassword = !showPlainPassword"
  >
    <template v-slot:progress>
      <v-progress-linear
        :color="strengthColor"
        :value="strengthProgress"
        absolute
        class="passwordStrengthMeter"
        height="7"
      ></v-progress-linear>
    </template>
  </v-text-field>
</template>

<script>
import { zxcvbn, ZxcvbnOptions } from '@zxcvbn-ts/core';

// lazy load the zxcvbn libraries.
const loadZxcvbnOptions = async () => {
  const zxcvbnCommonPackage = await import(
    /* webpackChunkName: "zxcvbnCommonPackage" */ '@zxcvbn-ts/language-common'
  );
  const zxcvbnEnPackage = await import(
    /* webpackChunkName: "zxcvbnEnPackage" */ '@zxcvbn-ts/language-en'
  );
  const zxcvbnNlPackage = await import(
    /* webpackChunkName: "zxcvbnNlPackage" */ '@zxcvbn-ts/language-nl-be'
  );

  return {
    translations: zxcvbnNlPackage.default.translations,
    dictionary: {
      ...zxcvbnCommonPackage.default.dictionary,
      ...zxcvbnEnPackage.default.dictionary,
      ...zxcvbnNlPackage.default.dictionary,
    },
  };
};

/**
 * Password field component.
 *
 * This component can be used to enter passwords, it has a `show plain text` btn and follows best
 * practices for password managers.
 *
 * Example:
 * <PasswordField v-model="pwd" name="pass" label="Password" hide-plain-password-toggle>
 */
export default {
  name: 'PasswordField',

  props: {
    // The v-model value
    value: {
      required: true,
    },
    // hide the plain/password toggle btn
    hidePlainPasswordToggle: {
      type: Boolean,
      default: false,
    },
    // true when the field is used to enter a new password ( sign up, recovery )
    // false when the field is used to enter an existing password ( login )
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values
    newPassword: {
      type: Boolean,
      default: true,
    },
    // https://vuetifyjs.com/en/api/v-text-field/
    errorMessages: {
      type: [Array, String],
    },
    name: String,
    required: Boolean,
    label: String,
    outlined: Boolean,
    hint: String,
    rules: Array,
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex
    tabindex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      // the input value
      plainPassword: this.value,

      // plain / password toggle, true to show the plain value
      showPlainPassword: false,

      /**
       * The password strength score. 0 weak password; 4 strong password.
       *
       * @type {number}
       */
      strengthScore: 0,

      /**
       * Suggestions for a better password.
       *
       * @type {string}
       */
      passwordSuggestion: this.hint,
    };
  },

  computed: {
    /**
     * @type {string} Text or password
     */
    fieldType() {
      if (this.hidePlainPasswordToggle === true) {
        // the field type is always password when the user can't toggle
        return 'password';
      }

      return this.showPlainPassword ? 'text' : 'password';
    },

    /**
     * @type {string|null} Null when the icon shouldn't be shown.
     */
    showPasswordIcon() {
      if (this.hidePlainPasswordToggle === true) {
        // do not show the toggle icon
        return null;
      }

      return this.showPlainPassword ? 'mdi-eye' : 'mdi-eye-off';
    },

    /**
     * @type {string} Auto complete type, to help password managers.
     */
    autocomplete() {
      return this.newPassword ? 'new-password' : 'current-password';
    },

    /**
     * @type {string}
     */
    strengthColor() {
      return ['error', 'error', 'warning', 'success', 'success'][this.strengthScore];
    },

    /**
     * @type {number}
     */
    strengthProgress() {
      return this.strengthScore * 25;
    },

    /**
     * @type {boolean}
     */
    isStrengthIndicatorShown() {
      const password = this.plainPassword;

      return (typeof password === 'string' && password.length > 0);
    },
  },

  watch: {
    /**
     * Synchronise the v-model with the entered password and calculates the password strength score.
     *
     * @param {string} value
     */
    async plainPassword(value) {
      this.$emit('input', value);

      if (typeof value !== 'string' || value.length === 0 || this.newPassword === false) {
        this.strengthScore = 0;
        this.passwordSuggestion = this.hint;

        // there is no input value
        return;
      }

      const options = await loadZxcvbnOptions();
      ZxcvbnOptions.setOptions(options);
      const { score, feedback } = zxcvbn(value);

      this.strengthScore = score;
      this.passwordSuggestion = feedback.suggestions[feedback.suggestions.length - 1] || this.hint;
    },
  },
};
</script>

<style lang="sass">
.passwordStrengthMeter
  width: calc(100% - 6px)
  margin: -9px 0 0 3px
  border-radius: 3px
</style>
