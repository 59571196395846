<template>
  <v-navigation-drawer
    :expand-on-hover="mini"
    :floating="!mini"
    :mini-variant="mini"
    :value="true"
    app
    clipped
    data-test="appNavigation"
    permanent
    width="300"
  >

    <v-list
      dense
      expand
      nav
      shaped
    >
      <template v-for="(item, itemKey) in navigationItems">

        <template v-if="item.children === undefined">
          <v-list-item :key="itemKey" :to="item.to">
            <v-list-item-icon v-if="item.icon !== undefined">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </template>

        <template v-else>
          <v-list-group
            :key="itemKey"
            :group="item.group"
            :prepend-icon="item.icon"
            no-action
          >

            <template v-slot:activator>
              <v-list-item>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item>
            </template>

            <template v-for="(child, childKey) in item.children">
              <v-list-item :key="childKey" :to="child.to">
                <v-list-item-icon v-if="child.icon !== undefined">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ child.label }}</v-list-item-title>
              </v-list-item>
            </template>

          </v-list-group>
        </template>

      </template>
    </v-list>

    <template v-slot:append>
      <v-list nav shaped dense>

        <v-list-item v-if="isFeatureEnabled('SETTINGS_VIEW')" :to="{ name: 'Settings' }">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ _('settings_label') }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="mini = !mini">
          <v-list-item-icon v-if="mini">
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-list-item-icon>
          <v-list-item-icon v-else>
            <v-icon>mdi-chevron-double-left</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ _('collapse_navigation_drawer_label') }}</v-list-item-title>
        </v-list-item>

      </v-list>
    </template>

  </v-navigation-drawer>
</template>

<script>
import permissionsMixin from '@/mixins/permissions';
import settingsMixin from '@/mixins/settings';
import translateMixin from '@/mixins/translate';

/**
 * Default navigation drawer.
 *
 * This component contains the navigation items configured in the /router/routes.js file.
 * Some options in the router meta config are related to this navigation component.
 *
 * @see /router/routes.js for more information.
 */
export default {
  name: 'AppNavigation',

  mixins: [permissionsMixin, settingsMixin, translateMixin],

  data() {
    return {
      // @see translateMixin.translateKeyPrefix
      translateKeyPrefix: 'components.layout.app_navigation',

      // true to minimize the navigation drawer
      mini: true,
      // an easter-egg to toggle the settings feature.
      // when the toggle mini button is clicked 10 times within 2 seconds, the settings feature is
      // enabled.
      miniBtnClickCount: 0,
    };
  },

  computed: {
    /**
     * Returns all routes which can be used for navigation this app.
     *
     * @returns {Object[]}
     */
    navigationItems() {
      const { isFeatureEnabled, isGranted } = this;

      // private recursive function to create navigation items and there sub items.
      function createNavFromRoutes(routes) {
        const navItems = [];
        routes.forEach((route) => {
          const { children, meta } = route;
          const {
            showInNavigation,
            icon,
            label,
            feature,
            roles,
          } = meta || {};
          let navItem = {};

          if (Array.isArray(roles) === true && isGranted(roles) === false) {
            // the user is not granted to access this route, do not display it in the navigation
            return;
          }

          if (feature !== undefined && isFeatureEnabled(feature) === false) {
            // the route is disabled by a feature flag
            return;
          }

          if (showInNavigation === true) {
            // create a navigation item
            navItem = {
              icon: `mdi-${icon}`,
              label,
              to: route,
            };
          }

          if (children && Array.isArray(children) === true) {
            const childNavItem = createNavFromRoutes(children);

            if (showInNavigation === true) {
              // add the child nav items to the parent
              navItem.group = route.path;
              navItem.children = childNavItem;
            } else {
              // add the child nav items to the root
              navItems.push(...childNavItem);
            }
          }

          if (
            Array.isArray(navItem.children) === true
            && navItem.children.length === 0
          ) {
            return;
          }

          if (showInNavigation === true) {
            navItems.push(navItem);
          }
        });

        return navItems;
      }

      return createNavFromRoutes(this.$router.options.routes);
    },
  },

  watch: {
    // an easter-egg to toggle the settings feature.
    // when the toggle mini button is clicked 10 times within 2 seconds, the settings feature is
    // enabled.
    mini() {
      this.miniBtnClickCount += 1;
      if (this.miniBtnClickCount === 10) {
        this.$store.commit('settings/UPDATE_FEATURE_FLAGS', { SETTINGS_VIEW: !this.isFeatureEnabled('SETTINGS_VIEW') });
        this.miniBtnClickCount = 0;
      }
    },
  },
};
</script>
