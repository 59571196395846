import VuexORM from '@vuex-orm/core';
import VuexORMSearch from '@vuex-orm/plugin-search';
import JsonResourceFactory from '@/services/api/JsonResourceFactory';
import HydraResourceFactory from '@/services/api/HydraResourceFactory';
import databaseModuleFactory from '@/store/databaseModuleFactory';
import companyDatabaseModuleFactory from '@/store/module/companyDatabaseModuleFactory';
import rpcDatabaseModuleFactory from '@/store/module/rpcDatabaseModuleFactory';
import Address from '@/model/Address';
import Company from '@/model/Company';
import Contact from '@/model/Contact';
import Article from '@/model/Article';
import ArticleFeature from '@/model/ArticleFeature';
import Product from '@/model/Product';
import ProductFeature from '@/model/ProductFeature';
import User from '@/model/User';
import Vue from 'vue';

/**
 * VueX database plugin.
 *
 * This plugin adds ORM functionalities under a `database` namespace in the state store. It uses the
 * models defined in `/src/model/` to build a schema. Every model is a table in the schema.
 *
 * The plugin adds getters to query the database and actions to mutate the database.
 *
 * Query data:
 * `$store.getters['database/<table>/query']().all();`
 *
 * Insert data:
 * `$store.dispatch('database/<table>/insert', { data: <data> });`
 *
 * The databaseModuleFactory adds extra functionality to load and save data from an external source.
 * `$store.dispatch('database/<resource>/load', <id>);`
 * `$store.dispatch('database/<resource>/patch', <payload>);`
 * `$store.dispatch('database/<resource>/persist', <model>);`
 * `$store.dispatch('database/<resource>/remove', <id>);`
 *
 * @see /src/model/
 * @see https://vuex-orm.org/
 */
export default async (store) => {
  await Vue.nextTick();
  const apis = store.getters['settings/apis'];
  const database = new VuexORM.Database();
  database.register(User, databaseModuleFactory(User.entity, JsonResourceFactory(`${apis.login_api}/user`)));
  database.register(Company, companyDatabaseModuleFactory(Company.entity, JsonResourceFactory(`${apis.company_manager}/companies`)));
  database.register(Address, databaseModuleFactory(Address.entity, JsonResourceFactory(`${apis.company_manager}/addresses`)));
  database.register(Contact, databaseModuleFactory(Contact.entity, JsonResourceFactory(`${apis.company_manager}/contacts`)));
  database.register(Article, rpcDatabaseModuleFactory(Article.entity, HydraResourceFactory(`${apis.product_article_manager}/articles`)));
  database.register(ArticleFeature, rpcDatabaseModuleFactory(ArticleFeature.entity, HydraResourceFactory(`${apis.product_article_manager}/articles/{ibCode}/features`)));
  database.register(Product, rpcDatabaseModuleFactory(Product.entity, HydraResourceFactory(`${apis.product_article_manager}/products`)));
  database.register(ProductFeature, rpcDatabaseModuleFactory(ProductFeature.entity, HydraResourceFactory(`${apis.product_article_manager}/products/{ibCode}/features`)));

  VuexORM.use(VuexORMSearch, {});
  VuexORM.install(database, { namespace: 'database' })(store);
};
