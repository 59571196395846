<template>
  <v-app-bar
    app
    clipped-left
    color="appBar"
    dark
  >

    <div class="d-flex align-center">
      <a href="/">
        <v-img
          :alt="_('logo_alt')"
          :src="require('@/assets/logo/logo-inverted.png')"
          class="shrink mr-2"
          transition="scale-transition"
          width="35"
        />
      </a>
      <span class="text-caption grey--text">{{ appVersion }}</span>
    </div>

    <v-spacer></v-spacer>

    <SessionMenu v-if="hasSession"></SessionMenu>

  </v-app-bar>
</template>

<script>
import SessionMenu from '@/components/layout/AppBarSessionMenu.vue';
import translateMixin from '@/mixins/translate';

/**
 * Default toolbar, shown on the top of the app.
 */
export default {
  name: 'AppBar',

  mixins: [translateMixin],

  components: { SessionMenu },

  data() {
    return {
      // @see translateMixin.translateKeyPrefix
      translateKeyPrefix: 'components.layout.app_bar',
      // the app version
      appVersion: process.env.VUE_APP_VERSION,
    };
  },

  computed: {
    /**
     * @return {boolean}
     */
    hasSession() {
      return this.$store.getters['session/hasSession'];
    },
  },
};
</script>
