/**
 * Factory function to create a VuexORM module.
 *
 * This module adds Vuex actions that can be used to synchronize the internal database state with an
 * external source.
 *
 * @see https://vuex-orm.org/guide/digging-deeper/vuex-module.html#defining-modules
 *
 * @param {string} entity
 * @param {object} api
 * @return {{
 *   mutations: {
 *     UPDATE_TOTAL_ITEMS(*, *): void
 *   },
 *   state: {
 *     totalItems: undefined
 *   },
 *   getters: {
 *     findBy: (function(*, *): function(*): *)
 *   },
 *   actions: {
 *     patch({dispatch: *}, {parameters?: {any}, payload?: {any}}): Promise<Array<*>>,
 *     load({dispatch: *}, {parameters?: {any}}): Promise<Array<*>>,
 *     loadBy({dispatch: *, commit: *}, {parameters?: {any}}): Promise<Array<*>>,
 *     persist({dispatch: *}, {parameters?: {any}, payload?: {any}}): Promise<Array<*>>,
 *     put({dispatch: *}, {parameters?: {any}, payload?: {any}}): Promise<Array<*>>,
 *     remove({dispatch: *}, {parameters?: {any}}): Promise<void>
 *   },
 *   namespaced: boolean
 * }}
 */
export default (entity, api) => ({
  namespaced: true,

  state: {
    totalItems: undefined,
  },

  mutations: {
    UPDATE_TOTAL_ITEMS(state, totalItems) {
      if (Number.isInteger(totalItems) === true) {
        state.totalItems = totalItems;
      }
    },
  },

  getters: {
    findBy: (_, getters) => (criteria) => {
      const { page, itemsPerPage } = criteria;
      const queryBuilder = getters.query();
      queryBuilder
        .limit(itemsPerPage)
        .offset(itemsPerPage * (page - 1));

      if (criteria?.order !== undefined) {
        Object
          .entries(criteria.order)
          .forEach(([key, value]) => {
            queryBuilder.orderBy(key, value);
          });
      }

      if (criteria.filters !== undefined && typeof criteria.filters === 'object') {
        Object
          .entries(criteria.filters)
          .forEach(([filter, value]) => {
            queryBuilder.where(filter, value.toString());
          });
      }

      if (criteria?.q !== undefined && criteria.q.length > 0) {
        queryBuilder.search(criteria.q, {
          threshold: 0.5,
          ignoreLocation: true,
          keys: ['ibCode', 'articleId', 'description'],
        });
      }

      return queryBuilder.get();
    },
  },

  actions: {
    /**
     * @param dispatch
     * @param commit
     * @param {{any}} parameters
     * @return {Promise<array<*>>}
     */
    async loadBy({ dispatch, commit }, { parameters = {} }) {
      const { data, total } = await api.fetchCollection(parameters);

      commit('UPDATE_TOTAL_ITEMS', total);

      return dispatch('database/insert', { entity, data }, { root: true });
    },

    /**
     * Load a model.
     *
     * @param dispatch
     * @param {{any}} parameters
     * @return {Promise<array<*>>}
     */
    async load({ dispatch }, { parameters = {} }) {
      const { data } = await api.fetchOne(parameters);

      return dispatch('database/insert', { entity, data }, { root: true });
    },

    /**
     * Patch a model in the resource collection.
     *
     * @param dispatch
     * @param {{any}} parameters
     * @param {{any}} payload
     * @return {Promise<array<*>>}
     */
    async patch({ dispatch }, { parameters = {}, payload = {} }) {
      const { data } = await api.patchOne(parameters, payload);

      return dispatch('database/update', { entity, data }, { root: true });
    },

    /**
     * Replace a model in the resource collection.
     *
     * @param dispatch
     * @param {{any}} parameters
     * @param {{any}} payload
     * @return {Promise<array<*>>}
     */
    async put({ dispatch }, { parameters = {}, payload = {} }) {
      const { data } = await api.updateOne(parameters, payload);

      return dispatch('database/update', { entity, data }, { root: true });
    },

    /**
     * Add a model to the resource collection.
     *
     * @param dispatch
     * @param {{any}} parameters
     * @param {{any}} payload
     * @return {Promise<array<*>>}
     */
    async persist({ dispatch }, { parameters = {}, payload = {} }) {
      const { data } = await api.createOne(parameters, payload);

      return dispatch('database/insert', { entity, data }, { root: true });
    },

    /**
     * Remove a model from the collection.
     *
     * @param dispatch
     * @param {{any}} parameters
     * @return {Promise<void>}
     */
    async remove({ dispatch }, { parameters = {} }) {
      await api.removeOne(parameters);
      await dispatch('database/delete', { entity, where: parameters?.id }, { root: true });
    },
  },
});
