export const ROLE_ADDON_COMPANY_ADMIN = 'ROLE_ADDON_COMPANY_ADMIN';
export const ROLE_ADDON_CATALOG_MANAGER = 'ROLE_ADDON_CATALOG_MANAGER';
export const ROLE_IB_ADMIN = 'ROLE_IB_ADMIN';
export const ROLE_IB_EMPLOYEE = 'ROLE_IB_EMPLOYEE';

export default {
  computed: {
    /**
     * Returns whether the user has one of the given required roles.
     *
     * @return {function(array<string>): boolean}
     */
    isGranted() {
      return (requiredRoles) => {
        const userRoles = this.$store.getters['session/roles'];

        return userRoles.some((userRole) => requiredRoles.includes(userRole));
      };
    },
  },
};
