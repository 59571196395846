<template>
  <v-menu
    bottom
    min-width="250"
    rounded
  >

    <template v-slot:activator="{ on }">
      <v-btn
        data-test="sessionMenuActivator"
        icon
        x-large
        v-on="on"
      >
        <v-avatar size="48">
          <span>{{ userInitials }}</span>
        </v-avatar>
      </v-btn>
    </template>

    <v-card data-test="sessionMenu">
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">

          <v-avatar color="primary">
            <span class="white--text text-h5">{{ userInitials }}</span>
          </v-avatar>

          <h3>{{ fullName }}</h3>

          <p class="text-caption mt-1">{{ email }}</p>

          <v-divider class="my-3"></v-divider>

          <v-btn
            :to="{ name: 'Account' }"
            depressed
            rounded
            text
          >{{ _('my_account') }}</v-btn>

          <div v-if="canImpersonate">

            <v-divider class="my-3"></v-divider>

            <v-dialog max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  rounded
                  text
                  v-bind="attrs"
                  v-on="on"
                >{{ _('login_as') }}
                </v-btn>
              </template>

              <ImpersonateForm
                v-model="dto"
                :errorBag="errorBag"
                :loading="isLoading"
                data-test="impersonateForm"
                @submit="handleImpersonate"
              ></ImpersonateForm>
            </v-dialog>

          </div>

          <v-divider class="my-3"></v-divider>

          <v-btn
            depressed
            rounded
            text
            @click="logout"
          >{{ _('logout') }}</v-btn>

        </div>
      </v-list-item-content>
    </v-card>

  </v-menu>
</template>

<script>
import ImpersonateForm from '@/components/layout/AppBarSessionImpersonateForm.vue';
import formHandleMixin from '@/mixins/formHandle';
import permissionsMixin, { ROLE_IB_ADMIN, ROLE_IB_EMPLOYEE } from '@/mixins/permissions';
import redirectMixin from '@/mixins/redirect';
import sessionMixin from '@/mixins/session';
import translateMixin from '@/mixins/translate';
import BadRequestError from '@/services/http/errors/BadRequestError';
import ForbiddenError from '@/services/http/errors/ForbiddenError';
import NotFoundError from '@/services/http/errors/NotFoundError';
import UnauthorizedError from '@/services/http/errors/UnauthorizedError';

/**
 * Session menu.
 *
 * This component adds a button to the right of the AppBar.
 * The session menu is shown when this button is clicked.
 */
export default {
  name: 'AppBarSessionMenu',

  mixins: [
    formHandleMixin,
    permissionsMixin,
    redirectMixin,
    sessionMixin,
    translateMixin,
  ],

  components: { ImpersonateForm },

  data() {
    return {
      // @see translateMixin.translateKeyPrefix
      translateKeyPrefix: 'components.layout.session_menu',
    };
  },

  computed: {
    /**
     * @return {boolean}
     */
    canImpersonate() {
      return this.isGranted([ROLE_IB_EMPLOYEE, ROLE_IB_ADMIN]);
    },
  },

  methods: {
    /**
     * @return {void}
     */
    logout() {
      this.$store.dispatch('session/destroySession');

      this.redirectAfterLogout();
    },

    /**
     * @return {void}
     */
    async handleImpersonate() {
      try {
        this.isLoading = true;
        // try to authenticate
        await this.$store.dispatch('session/impersonate', this.dto);
      } catch (error) {
        if (error instanceof UnauthorizedError) {
          this.errorBag.email = this._('unauthorized');
        } else if (error instanceof ForbiddenError) {
          this.errorBag.email = this._('forbidden');
        } else if (error instanceof NotFoundError) {
          this.errorBag.email = this._('not_found');
        } else if (error instanceof BadRequestError) {
          this.$store.commit('messages/add', this._('bad_request'));
        } else {
          this.$store.commit('messages/add', this._('server_error'));
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
