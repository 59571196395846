import ContentLayout from '@/components/layout/ContentLayout.vue';
import MainLayout from '@/components/layout/MainLayout.vue';
import SplitScreenLayout from '@/components/layout/SplitScreenLayout.vue';
import { ROLE_ADDON_CATALOG_MANAGER, ROLE_ADDON_COMPANY_ADMIN } from '@/mixins/permissions';
import AccountView from '@/views/account/Account.vue';
import ChangePasswordView from '@/views/account/ChangePassword.vue';
import PersonalDataView from '@/views/account/PersonalData.vue';
import SessionView from '@/views/account/Session.vue';
import LoginView from '@/views/account/Login.vue';
import RecoveryView from '@/views/account/PasswordRecovery.vue';

const DashboardView = () => import(/* webpackChunkName: "common" */ '@/views/main/Dashboard.vue');
const SettingsView = () => import(/* webpackChunkName: "common" */ '@/views/main/Settings.vue');
const NotFoundView = () => import(/* webpackChunkName: "common" */ '@/views/error/NotFound.vue');
const CompanyInfoView = () => import(/* webpackChunkName: "company" */ '@/views/company/CompanyInfo.vue');
const Addresses = () => import(/* webpackChunkName: "company" */ '@/views/company/Addresses.vue');
const ContactPersons = () => import(/* webpackChunkName: "company" */ '@/views/company/ContactPersons.vue');
const LogoUpload = () => import(/* webpackChunkName: "company" */ '@/views/company/LogoUpload.vue');
const OpeningHours = () => import(/* webpackChunkName: "company" */ '@/views/company/OpeningHours.vue');
const ProductsView = () => import(/* webpackChunkName: "article" */ '@/views/article/Products.vue');
const ProductView = () => import(/* webpackChunkName: "article" */ '@/views/article/Product.vue');
const ArticlesView = () => import(/* webpackChunkName: "article" */ '@/views/article/Articles.vue');
const ArticleView = () => import(/* webpackChunkName: "article" */ '@/views/article/Article.vue');
// const SkuConfigView = () => import(/* webpackChunkName: "sku" */ '@/views/sku/Config.vue');
// const SkuNumbersView = () => import(/* webpackChunkName: "sku" */ '@/views/sku/Numbers.vue');

/**
 * Here are all routes defined.
 *
 * Options defined in meta:
 * - requiresAuth    : <bool> default=true
 * - roles           : <array<string>> The roles required to access the route
 * - showInNavigation: <bool> default=false
 * - navIcon         : <string> One of the materialdesignicons.com icon's without the `mdi-` prefix
 * - navLabel        : <string> Label used in the navigation
 * - feature         : <string> Feature flag name. Defined in the featureFlags.js
 *
 * @see https://router.vuejs.org/guide/essentials/named-routes.html
 */
export default [
  // main application routes
  {
    path: '/',
    component: MainLayout,
    meta: {
      showInNavigation: false,
    },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: DashboardView,
        meta: {
          showInNavigation: true,
          icon: 'view-dashboard',
          label: 'Dashboard',
        },
      },
      {
        path: '/instellingen',
        name: 'Settings',
        component: SettingsView,
        meta: {
          feature: 'SETTINGS_VIEW',
        },
      },
    ],
  },
  // company routes
  {
    path: '/bedrijf',
    component: MainLayout,
    meta: {
      showInNavigation: true,
      icon: 'domain',
      label: 'Mijn bedrijf',
    },
    children: [
      {
        path: 'gegevens',
        name: 'CompanyInfo',
        component: CompanyInfoView,
        meta: {
          roles: [ROLE_ADDON_COMPANY_ADMIN],
          showInNavigation: true,
          icon: 'information-outline',
          label: 'Bedrijfsgegevens',
        },
      },
      {
        path: 'contactpersonen',
        name: 'ContactPerson',
        component: ContactPersons,
        meta: {
          roles: [ROLE_ADDON_COMPANY_ADMIN],
          showInNavigation: true,
          icon: 'card-account-mail',
          label: 'Medewerkers',
        },
      },
      {
        path: 'adressen',
        name: 'Addresses',
        component: Addresses,
        meta: {
          roles: [ROLE_ADDON_COMPANY_ADMIN],
          showInNavigation: true,
          icon: 'map-marker',
          label: 'Adressen',
        },
      },
      {
        path: 'openingstijden',
        name: 'OpeningHours',
        component: OpeningHours,
        meta: {
          roles: [ROLE_ADDON_COMPANY_ADMIN],
          showInNavigation: true,
          icon: 'clock-outline',
          label: 'Openingstijden',
        },
      },
      {
        path: 'logo',
        name: 'LogoUpload',
        component: LogoUpload,
        meta: {
          roles: [ROLE_ADDON_COMPANY_ADMIN],
          showInNavigation: true,
          icon: 'camera-plus',
          label: 'Bedrijfslogo wijzigen',
        },
      },
    ],
  },
  {
    path: '/db',
    component: MainLayout,
    meta: {
      showInNavigation: true,
      icon: 'database-search',
      label: 'Mijn artikelen',
    },
    children: [
      {
        path: 'producten',
        name: 'Products',
        component: ProductsView,
        meta: {
          roles: [ROLE_ADDON_CATALOG_MANAGER],
          showInNavigation: true,
          icon: 'screw-flat-top',
          label: 'Producten',
        },
      },
      {
        path: 'producten/:ibCode',
        name: 'Product',
        component: ProductView,
        meta: {
          roles: [ROLE_ADDON_CATALOG_MANAGER],
          showInNavigation: false,
          icon: 'database-search',
          label: 'Producten',
        },
      },
      {
        path: 'artikelen',
        name: 'Articles',
        component: ArticlesView,
        meta: {
          roles: [ROLE_ADDON_CATALOG_MANAGER],
          showInNavigation: true,
          icon: 'wrench',
          label: 'Artikelen',
        },
      },
      {
        path: 'artikelen/:ibCode',
        name: 'Article',
        component: ArticleView,
        meta: {
          roles: [ROLE_ADDON_CATALOG_MANAGER],
          showInNavigation: false,
          icon: 'database-search',
          label: 'Artikelen',
        },
      },
    ],
  },
  // authentication routes
  {
    path: '/auth',
    component: SplitScreenLayout,
    meta: {},
    children: [
      {
        path: '/login',
        name: 'Login',
        component: LoginView,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: '/wachtwoord-aanvragen/:secret?',
        name: 'PasswordRecovery',
        component: RecoveryView,
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
  // account
  {
    path: '/account',
    component: MainLayout,
    meta: {},
    children: [
      {
        path: '/',
        name: 'Account',
        component: AccountView,
        meta: {},
      },
      {
        path: 'persoonsgegevens',
        name: 'PersonalData',
        component: PersonalDataView,
        meta: {},
      },
      {
        path: 'sessie',
        name: 'AccountSession',
        component: SessionView,
        meta: {},
      },
      {
        path: 'change-password',
        name: 'ChangePassword',
        component: ChangePasswordView,
        meta: {},
      },
    ],
  },
  //
  // {
  //   path: '/sku',
  //   component: MainLayout,
  //   meta: {
  //     showInNavigation: true,
  //     icon: 'barcode',
  //     label: 'SKU nummers',
  //   },
  //   children: [
  //     {
  //       path: '/',
  //       name: 'SkuConfig',
  //       component: SkuConfigView,
  //       meta: {
  //         // roles: [],
  //         showInNavigation: true,
  //         icon: 'barcode-scan',
  //         label: 'Generator',
  //       },
  //     },
  //     // {
  //     //   path: ':configId/numbers',
  //     //   name: 'SkuNumbers',
  //     //   component: SkuNumbersView,
  //     //   meta: {
  //     //     showInNavigation: false,
  //     //   },
  //     // },
  //   ],
  // },
  // error routes
  {
    path: '/error',
    name: 'Error',
    component: ContentLayout,
    children: [
      {
        path: '404',
        name: 'NotFoundView',
        component: NotFoundView,
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
  // catch route not found errors
  {
    path: '*',
    redirect: '/error/404',
  },
];
