import AbstractModel from '@/model/AbstractModel';
import Address from '@/model/Address';
import Contact from '@/model/Contact';

export default class Company extends AbstractModel {
  static entity = 'company';

  static primaryKey = 'uuid';

  static updateMethod = AbstractModel.PATCH;

  static fields() {
    return {
      id: this.uid(null).nullable(),
      uuid: this.uid(null).nullable(),
      name: this.string(null).nullable(),
      kvkNr: this.string(null).nullable(),

      phoneNr: this.string(null).nullable(),
      faxNr: this.string(null).nullable(),
      email: this.string(null).nullable(),
      website: this.string(null).nullable(),

      gln: this.string(null).nullable(), // ean_address_code
      extendedInfo: this.string(null).nullable(),

      openingHours: this.attr({}),
      logoUrl: this.string(''),

      contact: this.hasMany(Contact, 'company_id', 'id'),
      address: this.hasMany(Address, 'company_id', 'id'),
    };
  }

  get dto() {
    return this.$toJson();
  }

  set dto(data) {
    let dto = data;
    if (data instanceof AbstractModel) {
      dto = data.dto;
    }
    this.$fill(dto);
  }
}
