// this mixin together with the formHandle mixin is the successor of the form mixin.
// rename this mixin to form when the code is refactored and the current form mixin isn't used
// anymore

/**
 * Form helper mixin.
 *
 * Use this mixin in form components. A form component is a component with the `v-form` component.
 */
export default {
  props: {
    /**
     * Form components always have a v-model attribute that expects an object (AbstractModel.dto).
     *
     * Example: <AcmeForm v-model="dto".../>
     */
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    /**
     * Form loading state.
     */
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * Error-bag for external errors.
     *
     * This error bag is an object where the object key matches the form field name and the object
     * value are the error messages. An error message can be a `String` or `Array` of `String`s.
     */
    errorBag: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      /**
       * When Model properties are used as input v-model values, the model reference will illegally
       * update the values in the state store, thus updating the entire app. To prevent this, a DTO
       * is made of the model. A DTO can be any object that is updated by the form input fields and
       * that is submitted when the editing is complete.
       *
       * @see AbstractModel.dto
       *
       * @type {object}
       */
      dto: this.value,

      /**
       * Form validity state.
       *
       * @type {boolean}
       */
      isFormValid: true,

      /**
       * Default validation rules.
       *
       * @type {object}
       */
      defaultRules: {
        required: (v) => !!v || 'Dit veld is verplicht',
        email: (v) => /.+@.+/.test(v) || 'Vul een geldig e-mailadres in',
      },
    };
  },

  watch: {
    /**
     * Update the DTO when the v-model changes.
     *
     * @return {void}
     */
    value() {
      this.dto = this.value;
    },
  },

  methods: {
    /**
     * Validates all registered inputs. Returns true if successful and false if not
     *
     * You need to add a `ref="form"` attribute to the form components.
     * Example: <v-form refs="form">
     *
     * @return {boolean}
     */
    validate() {
      const { form } = this.$refs;
      if (typeof form?.validate === 'function') {
        return form.validate();
      }

      return false;
    },

    /**
     * Resets the form to its initial state.
     *
     * You need to add a `ref="form"` attribute to the form components.
     * Example: <v-form refs="form">
     *
     * @return {void}
     */
    reset() {
      const { form } = this.$refs;
      if (typeof form?.reset === 'function') {
        form.reset();
      }

      this.isFormValid = true;

      this.$emit('input', {});
      this.$emit('reset');
    },

    /**
     * Submit the current form state (the updated DTO).
     *
     * Example: <v-form refs="form" @submit.prevent="handleSubmit".../>
     *
     * @return {void}
     */
    submit() {
      if (this.validate() !== true) {
        return;
      }

      // sync the v-model value
      this.$emit('input', this.dto);
      // trigger the form submit event
      this.$emit('submit', this.dto);
    },
  },
};
