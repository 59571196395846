<template>
  <v-footer
    absolute
    app
    color="primary"
    dark
    inset
  >
    <v-container fluid>

      <v-row no-gutters>

        <template v-for="(section, sk) in navigation">
          <v-col :key="sk">
            <v-list
              color="transparent"
              dense
              rounded
              subheader
            >

              <v-subheader class="text-h6 font-weight-black white--text"
              >{{ section.header }}</v-subheader>

              <template v-for="(item, ik) in section.items">
                <v-list-item
                  :key="ik"
                  :href="item.href"
                  link
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

            </v-list>
          </v-col>
        </template>

        <v-col>
          <v-list
            color="transparent"
            dense
            rounded
            subheader
          >

            <v-subheader class="text-h6 font-weight-black white--text"
            >{{ _('contact_title') }}</v-subheader>

            <v-list-item>
              <v-list-item-content>
                <span class="caption">{{ _('contact_sub_title') }}</span>
              </v-list-item-content>
            </v-list-item>

            <v-list-item href="https://www.ib.nl/bedrijf/ib" link>
              <v-list-item-icon>
                <v-icon dense>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ _('company_name') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ _('company_address') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item href="tel:+31403041420" link>
              <v-list-item-icon>
                <v-icon dense>mdi-phone</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ _('company_phone') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item href="mailto:info@ib.nl" link>
              <v-list-item-icon>
                <v-icon dense>mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ _('company_email') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon dense>mdi-calendar-month</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ _('company_open_hours') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>
        </v-col>

      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <v-img
            :alt="_('logoA_alt')"
            :src="require('@/assets/logo/logo-inverted.png')"
            class="shrink ma-2 float-right"
            contain
            height="100"
            transition="scale-transition"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col class="text-center white--text" cols="12">
          <small class="copyright">
            ©{{ new Date().getFullYear() }}
            <span v-html="_('copyright')"></span>
          </small>
        </v-col>
      </v-row>

    </v-container>
  </v-footer>
</template>

<script>
import translateMixin from '@/mixins/translate';

/**
 * Default footer, shown on the bottom of the app.
 */
export default {
  name: 'AppFooter',

  mixins: [translateMixin],

  data() {
    return {
      // @see translateMixin.translateKeyPrefix
      translateKeyPrefix: 'components.layout.app_footer',

      navigation: [
        {
          header: 'Diensten & Producten',
          items: [
            {
              label: 'Pocketaccounts IB.NL',
              href: 'https://www.ib.nl/producten',
            },
            {
              label: 'IB Datamanagement',
              href: 'https://www.ib.nl/producten#datamanagement',
            },
            {
              label: 'IB Dataconnectie',
              href: 'https://www.ib.nl/producten#dataconnectie',
            },
            {
              label: 'IB Catalogus',
              href: 'https://www.ib.nl/producten#catalogus',
            },
            {
              label: 'IB API documentatie',
              href: 'https://www.ib.nl/apidoc/',
            },
          ],
        },
        {
          header: 'Over het bedrijf',
          items: [
            {
              label: 'IB the data company',
              href: 'https://www.ib.nl/producten#overib',
            },
            {
              label: 'IB Netwerk',
              href: 'https://www.ib.nl/netwerk',
            },
            {
              label: 'Historie',
              href: 'https://www.ib.nl/producten#historie',
            },
            {
              label: 'Veelgestelde vragen',
              href: 'https://www.ib.nl/faq',
            },
            {
              label: 'Vacatures',
              href: 'https://www.ib.nl/vacatures/',
            },
          ],
        },
        {
          header: 'Overig',
          items: [
            {
              label: 'Updateoverzicht',
              href: 'https://updates.ib.nl/',
            },
            {
              label: 'Update melden',
              href: 'https://www.ib.nl/updatemelden',
            },
            {
              label: 'Contactformulier',
              href: 'https://www.ib.nl/contact',
            },
            {
              label: 'Algemene voorwaarden',
              href: 'https://www.ib.nl/terms',
            },
            {
              label: 'Privacybeleid',
              href: 'https://www.ib.nl/privacy',
            },
            {
              label: 'Cookies',
              href: 'https://www.ib.nl/cookies',
            },
          ],
        },
      ],
    };
  },
};
</script>
