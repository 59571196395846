import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import database from '@/store/database';
import modules from '@/store/module/index';

Vue.use(Vuex);

/**
 * Synchronise the state store with the localStorage.
 *
 * @see https://github.com/championswimmer/vuex-persist
 * @type {VuexPersistence<unknown>}
 */
const vuexPersistence = new VuexPersistence({
  key: 'persistence-state',
  storage: window.localStorage,
  modules: ['openApi', 'settings'],
});

/**
 * The State Store bootstrap.
 *
 * This store is used as application state store. Two components can communicate with each other
 * using the state store, this way, components don't need to know each other, they don't even need
 * to be loaded.
 *
 * The state store is also used as local database. All data fetched from external sources (API's)
 * will be normalized and stored for later use.
 *
 * This store is an important peace of the application architecture and should be understood as
 * intended.
 *
 * - Actions: Actions are async functions which can be used for business or application logic.
 *            For example: actions can be used to fetch or push data from/to external sources.
 *            Actions do not return data or state, instead, they store the data in the database.
 *
 * - Mutations: Mutations are function which MUST be used to update the state. It's not allowed
 *              to update the state without mutations. Mutations are mostly called from actions, but
 *              it's allowed to call mutations from components if it makes sense. Mutations do not
 *              define business logic. The only logic allowed in mutations is to validate or
 *              normalize data before updating the state. Do not throw error's in mutations.
 *
 * - Getters: Getters are simple functions wich can be used to manipulate data before using it.
 *            Its allowed retrieving data from the state directly, without getters. Do not create
 *            getters which will return data from the state without manipulating it.
 *
 *
 *                         /////////////////////    ///////////
 *                      <= // getUserInitials // <= // state //
 * ///////////////    <=   /////////////////////    ///////////
 * // Component //  <=>                               ^
 * ///////////////    =>   ///////////////////        |
 *                      => // loadUserData // ========|
 *                         //////////////////
 *
 * A component first get the user's initials using a getter, which will read the raw user data from
 * the state, which is not available yet. In parallel, the component calls the loadUserData action
 * to fetch data from an external source. Once the data is available, it will be stored in the state
 * The getUserInitials getter is reactive and will automatically return the initials when available
 * in the state.
 *
 * @see https://vuex.vuejs.org/
 * @see https://vuex-orm.org/
 * @see https://vuejs.org/v2/guide/reactivity.html
 */
export default new Vuex.Store({
  plugins: [database, vuexPersistence.plugin],
  modules,
});
