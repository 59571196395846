import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import nl from '@/locale/nl';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        appBar: '#523362',
        primary: '#523362',
        secondary: '#1565C0',
        accent: '#a6ce39',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
      dark: {
        appBar: '#523362',
      },
    },
  },
  lang: {
    locales: { nl },
    current: 'nl',
  },
});
