import {
  DELETE,
  GET,
  jsonLdMimeType,
  PATCH,
  POST,
  PUT,
  createEndpoint,
  fetchJson,
} from '@/services/http';
import ApiResource from '@/services/api/ApiResource';

/**
 * Hydra API resource factory.
 *
 * Use this factory to create resource objects which can communicate with the API.
 *
 * @param {string} baseUrl The API resource endpoint.
 * @return {{
 *  createOne({any}, {any}, RequestInit): Promise<ApiResource>,
 *  patchOne({any}, {any}, RequestInit): Promise<ApiResource>,
 *  updateOne({any}, {any}, RequestInit): Promise<ApiResource>,
 *  fetchCollection({any}, RequestInit): Promise<ApiResource>,
 *  removeOne({any}, RequestInit): Promise<void>,
 *  fetchOne({any}, RequestInit): Promise<ApiResource>
 * }}
 */
export default (baseUrl) => ({
  /**
   * @param {{any}} parameters
   * @param {{any}} payload
   * @param {RequestInit} options
   *
   * @return {Promise<ApiResource>}
   */
  async createOne(parameters, payload, options = {}) {
    const url = createEndpoint(baseUrl, parameters);
    const { body } = await POST(url, payload, {
      ...options,
      headers: { accept: jsonLdMimeType },
    });

    return new ApiResource(body);
  },

  /**
   * @param {{any}} parameters
   * @param {{any}} payload
   * @param {RequestInit} options
   *
   * @return {Promise<ApiResource>}
   */
  async patchOne(parameters, payload, options = {}) {
    const url = createEndpoint(baseUrl, parameters);
    const { body } = await PATCH(url, payload, options);

    return new ApiResource(body);
  },

  /**
   * @param {{any}} parameters
   * @param {{any}} payload
   * @param {RequestInit} options
   *
   * @return {Promise<ApiResource>}
   */
  async updateOne(parameters, payload, options = {}) {
    const url = createEndpoint(baseUrl, parameters);
    const { body } = await PUT(url, payload, {
      ...options,
      headers: { accept: jsonLdMimeType },
    });

    return new ApiResource(body);
  },

  /**
   * @param {{any}} parameters
   * @param {RequestInit} options
   *
   * @return {Promise<ApiResource>}
   */
  async fetchCollection(parameters, options = {}) {
    const url = createEndpoint(baseUrl, parameters);
    const { body } = await GET(url, {
      ...options,
      headers: { accept: jsonLdMimeType },
    });

    return new ApiResource(body['hydra:member'], body['hydra:totalItems']);
  },

  /**
   * @param {{any}} parameters
   * @param {RequestInit} options
   *
   * @return {Promise<ApiResource>}
   */
  async fetchOne(parameters, options = {}) {
    const url = createEndpoint(baseUrl, parameters);
    const { body } = await GET(url, {
      ...options,
      headers: { accept: jsonLdMimeType },
    });

    return new ApiResource(body);
  },

  /**
   * @param {{any}} parameters
   * @param {RequestInit} options
   *
   * @return {Promise<void>}
   */
  async removeOne(parameters, options = {}) {
    const url = createEndpoint(baseUrl, parameters);
    await DELETE(url, {
      ...options,
      headers: { accept: jsonLdMimeType },
    });
  },

  /**
   * @param {string} endpoint
   * @param {{any}} payload
   * @param {RequestInit} options
   * @return {Promise<ApiResource>}
   */
  async requestEndpoint(endpoint, payload, options = {}) {
    const url = new URL(endpoint, baseUrl).toString();
    const { body } = await fetchJson(url, {
      ...options,
      method: 'POST',
      body: JSON.stringify(payload),
    });

    return new ApiResource(body);
  },
});
