import OpenApi from '@/services/openapi/model/OpenApi';

/**
 * OpenApi store module.
 *
 * This module is used to store OpenApi specification and extract useful information from it.
 */
export default {
  namespaced: true,

  state: () => ({
    // true when the enum values are loaded
    loaded: false,

    /**
     * @type {{url: string, spec: OpenApi}}
     */
    specs: {},
  }),

  mutations: {
    /**
     * @param state
     * @param {string} url
     * @param {OpenApi} spec
     * @return void
     */
    UPDATE_API_SPECS(state, { url, spec }) {
      state.specs[url] = spec.dto;
    },

    /**
     * @param state
     * @return void
     */
    TOGGLE_LOADED(state) {
      state.loaded = !state.loaded;
    },
  },

  getters: {
    /**
     * @param state
     * @return {array<{server: string, name: string, version: string}>}
     */
    apis: (state) => Object.values(state.specs).map((openApi) => ({
      version: openApi.info.version,
      name: openApi.info.title,
      server: (openApi.servers[0] || {})?.url,
    })),

    /**
     * @param state
     * @return {{string: array<string>}}
     */
    enums: (state) => {
      const enums = {};
      Object.values(state.specs).forEach((openApi) => {
        openApi.components.schemas.forEach(([name, schema]) => {
          if (schema?.enum?.length > 0) {
            enums[name.toLowerCase()] = schema.enum;
          }
        });
      });

      return enums;
    },
  },

  actions: {
    /**
     * @param commit
     * @param state
     * @param {string} url
     * @return {Promise<void>}
     */
    async loadSpecs({ commit, state }, url) {
      if (state.loaded === true) {
        return;
      }

      const headers = new Headers();
      headers.set('Accept', 'application/json');
      const response = await fetch(url, { headers });
      const data = await response.json();
      const spec = new OpenApi(data);
      const defaultServer = spec?.servers[0] || {};

      commit('UPDATE_API_SPECS', { url: defaultServer?.url, spec });
      commit('TOGGLE_LOADED');
    },
  },
};
