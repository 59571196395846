/**
 * @see https://spec.openapis.org/oas/latest.html#server-variable-object
 */
export default class ServerVariable {
  /**
   * @type {array<string>}
   */
  enum = [];

  /**
   * @type {string}
   */
  default;

  /**
   * @type {string}
   */
  description;

  constructor(serverVariable) {
    this.default = serverVariable?.default || '';
    this.description = serverVariable?.description || '';

    if (Array.isArray(serverVariable?.enum) === true) {
      this.enum = serverVariable.enum;
    }
  }
}
