<template>
  <v-row justify="center">
    <v-col>

      <v-row justify="center">
        <v-img
          :alt="_('ib_thedatacompany_logo')"
          :src="require('@/assets/logo/logo.png')"
          class="shrink my-5"
          contain
          height="152"
          transition="scale-transition"
        />
      </v-row>

      <v-row justify="center">
        <p class="caption blue-grey--text--text text-center ma-3"
        >{{ _('request_password_recovery') }}</p>
      </v-row>

      <v-row justify="center">
        <v-card v-if="recoverySuccess" outlined width="500">
          <RecoverySecretRedeemForm
            ref="redeemForm"
            v-model="redeemModel"
            :errorBag="errorBag"
            :loading="isLoading"
            @reset="recoverySuccess = false"
            @submit="handleRedeemSecret"
          ></RecoverySecretRedeemForm>
        </v-card>

        <v-card v-else outlined width="500">
          <PasswordRecoveryForm
            ref="recoveryForm"
            v-model="recoveryModel"
            :errorBag="errorBag"
            :loading="isLoading"
            @submit="handleRecovery"
          ></PasswordRecoveryForm>
        </v-card>
      </v-row>

    </v-col>
  </v-row>
</template>

<script>
import PasswordRecoveryForm from '@/components/account/PasswordRecoveryForm.vue';
import RecoverySecretRedeemForm from '@/components/account/RecoverySecretRedeemForm.vue';
import formHandleMixin from '@/mixins/formHandle';
import redirectMixin from '@/mixins/redirect';
import translateMixin from '@/mixins/translate';
import ForbiddenError from '@/services/http/errors/ForbiddenError';
import NotFoundError from '@/services/http/errors/NotFoundError';
import UnprocessableEntityError from '@/services/http/errors/UnprocessableEntityError';

/**
 * Password recovery view.
 *
 * This view has two form components. The default is the "Request a password reset" form.
 * Once the user submitted there identity, the "Redeem secret" form is displayed which can be used
 * to enter the recovery secret.
 *
 * If the secret is found in the route parameters (URL), the "Redeem secret" form is displayed, the
 * secret is filled and the form is submitted automatically.
 */
export default {
  name: 'PasswordRecoveryView',

  components: {
    PasswordRecoveryForm,
    RecoverySecretRedeemForm,
  },

  mixins: [
    formHandleMixin,
    redirectMixin,
    translateMixin,
  ],

  data: () => ({
    // @see translateMixin.translateKeyPrefix
    translateKeyPrefix: 'views.account.password_recovery',

    // the form model
    recoveryModel: {
      email: '',
    },

    // redeem secret model
    redeemModel: {
      secret: '',
    },

    // true when the recovery request succeed, this will display a success message
    recoverySuccess: false,
  }),

  mounted() {
    // fill the redeem-form with the secret found in the route
    const { secret } = this.$route.params;
    if (secret === undefined) {
      this.$refs.recoveryForm.reset();
      return;
    }

    this.recoverySuccess = true;
    this.redeemModel.secret = secret;

    // give the page some time to fully load before submitting
    setTimeout(this.handleRedeemSecret, 10);
  },

  methods: {
    /**
     * Start the password recovery process.
     *
     * @return {Promise<void>}
     */
    async handleRecovery() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('account/requestAccountRecovery', this.recoveryModel);

        this.recoverySuccess = true;
        this.displayMessage(this._('redeem_recovery_secret_succeed'));
      } catch (error) {
        if (error instanceof UnprocessableEntityError) {
          this.errorBag = { ...this.errorBag, ...error.getViolations() };
        } else {
          this.displayMessage(this._('unknown_error'));
        }
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * Exchange a recovery secret for a temporary login token.
     *
     * @return {Promise<void>}
     */
    async handleRedeemSecret() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('account/redeemRecoverySecret', this.redeemModel);

        this.redirectAfterLogin('ChangePassword');
      } catch (error) {
        if (error instanceof ForbiddenError) {
          this.errorBag.secret = [this._('secret_is_expired')];
        } else if (error instanceof NotFoundError) {
          this.errorBag.secret = [this._('recovery_not_started')];
        } else {
          this.displayMessage(this._('unknown_error'));
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
