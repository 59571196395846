import { Model } from '@vuex-orm/core';
import { generate } from 'json-merge-patch';

export default class AbstractModel extends Model {
  static PUT = 'PUT';

  static PATCH = 'PATCH';

  /**
   * The method used to update this model.
   *
   * - PATCH
   * - PUT
   *
   * @type {string}
   */
  static updateMethod = AbstractModel.PUT;

  /**
   * @return {Record}
   */
  get dto() {
    return this.$toJson();
  }

  set dto(data) {
    this.$fill(data);
  }

  /**
   * Returns a JSON Merge Patch (RFC 7396).
   *
   * @return {{}}
   */
  get mergePatch() {
    const { dto } = this.$find(this.$getIndexIdFromAttributes());
    return generate(dto, this.dto);
  }
}
