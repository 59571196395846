import { GET, PATCH, POST } from '@/services/http';

/**
 * Account store module.
 *
 * This module is used to store account data about the authenticated user.
 *
 * Notice: The difference between this module and the session module is that the session module
 * is responsible for everything related to authenticating and the logged-in state.
 * This module is responsible for account recovery, fetching and updating account data.
 */
export default {
  namespaced: true,

  actions: {
    /**
     * Update the user password.
     *
     * The user will be re-authenticated when the user is changed, this way te updated data will
     * be updated in the system.
     *
     * @param _
     * @param {{ password: string, firstName: string, lastName: string }} user
     * @return {Promise<void>}
     */
    async patchPassword({ dispatch, rootState }, user) {
      const userData = user;
      if (userData.password === undefined) {
        // a new password is required
        return;
      }

      if (userData?.id !== undefined) {
        delete userData.id;
      }

      // update user data, no need to update the store, we will re-authenticate
      const { apis } = rootState.settings;
      await PATCH(`${apis.login_api}/user/${rootState.session.id}`, userData);

      // re-authenticate after the user is updated
      await dispatch('session/authenticateWithUsernameAndPassword',
        {
          email: rootState.session.email,
          password: userData.password,
        },
        { root: true });
    },

    /**
     * Request an account recovery.
     *
     * The recovery API will respond with no content, regardless the recovery success.
     *
     * @param _
     * @param {{email: string}} identity
     * @return {Promise<void>}
     */
    async requestAccountRecovery({ rootState }, identity) {
      const { apis } = rootState.settings;
      await POST(`${apis.login_api}/recovery`, identity);
    },

    /**
     * Exchange the recovery secret for a temporary auth token.
     *
     * @param _
     * @param {{secret: string}} secret
     * @return {Promise<void>}
     */
    async redeemRecoverySecret({ dispatch, rootState }, secret) {
      const { apis } = rootState.settings;
      const { body } = await GET(`${apis.login_api}/recovery/${secret.secret}`);
      const { token } = body;

      dispatch('session/createSession', token, { root: true });
    },
  },
};
